import m from "mithril";
import Stream from "mithril/stream";
import {get} from "../Utils";


export const SubmitCancelWidget = () => {
    return {
        view(vnode){
            return m("div.field.is-grouped",
                m("div.control.is-expanded",
                  m("button.button.is-link.is-fullwidth",
                      {
                          onclick(e){
                              get(vnode.attrs, "onSubmit", function(e){})(e);
                          }
                      },
                      get(vnode.attrs, "submitText", "Submit"))
                ),
                m("div.control.is-expanded",
                  m("button.button.is-link.is-light.is-fullwidth",
                      {
                          onclick(e){
                              get(vnode.attrs, "onCancel", function(e){})(e);
                          }
                      },
                      get(vnode.attrs, "cancelText", "Cancel"))
                )
            );
        }
    };
};