import {AuthAPI} from "./Auth";
import {CoreServiceConfig} from "./Core";


export const ApplicationAPI = {
    apiRequest(options, needsAuth = true, version = '/v0',
               additionalConfigHandler=function(xhr){}){
        return AuthAPI.jwtAuthenticatedRequest({
            ...options,
            config: additionalConfigHandler,
            url: CoreServiceConfig.calculateUrl(`${version}${options.url}`),
            jwtRequired: needsAuth
        });
    },
    getTags: function(){
        return ApplicationAPI.apiRequest({
            url: "/resource-tags"
        }, false);
    },
    getTagByID: function(tag_id){
        return ApplicationAPI.apiRequest({
            url: `/resource-tags/${tag_id}`
        }, false);
    },
    getResourcesByTagID: function(tag_id){
        return ApplicationAPI.apiRequest({
            url: `/resource-tags/${tag_id}/resources`
        }, false);
    },
    getCategories: function(){
        return ApplicationAPI.apiRequest({
            url: "/resource-categories"
        }, false);
    },
    addCategory: function(category_name, category_description, is_private=false){
        return ApplicationAPI.apiRequest({
            url: "/resource-categories",
            method: "POST",
            responseType: "json",
            body: {
                resource_category_name: category_name,
                resource_category_description: category_description,
                is_private
            }
        }, true);
    },
    getCategoryByID: function(category_id){
        return ApplicationAPI.apiRequest({
            url: `/resource-categories/${category_id}`
        }, false);
    },
    editCategoryByID: function(category_id, new_name, new_description, is_private=null){
        return ApplicationAPI.apiRequest({
            url: `/resource-categories/${category_id}`,
            method: "PUT",
            responseType: "json",
            body: {
              resource_category_name: new_name,
              resource_category_description: new_description,
              is_private
            },
        }, true);
    },
    deleteCategoryByID: function(category_id){
        return ApplicationAPI.apiRequest({
            url: `/resource-categories/${category_id}`,
            method: "DELETE"
        });
    },
    getResourcesByCategoryID: function(category_id){
        return ApplicationAPI.apiRequest({
            url: `/resource-categories/${category_id}/resources`
        }, false);
    },
    getResourceByID: function(resource_id){
        return ApplicationAPI.apiRequest({
            url: `/resources/${resource_id}`
        }, false);
    },
    addResource: function(name, primary_category_id, description, location=null, tags=[],
                          urls=[], contact_infos=[], is_private=false){
        return ApplicationAPI.apiRequest({
            url: "/resources",
            method: "POST",
            responseType: "json",
            body: {
                resource_name: name,
                primary_category_id,
                resource_description: description,
                resource_location: location,
                tags,
                resource_urls: urls,
                resource_contact_infos: contact_infos,
                is_private
            }
        }, true);
    },
    editResourceByID: function(resource_id, name=null, primary_category_id=null, description=null,
                               location=null, tags=null, urls=null, contact_info_values=null,
                               is_private=null){
        return ApplicationAPI.apiRequest({
            url: `/resources/${resource_id}`,
            method: "PUT",
            responseType: "json",
            body: {
                resource_name: name,
                primary_category_id: primary_category_id,
                resource_description: description,
                resource_location: location,
                tags: tags,
                url_values: urls,
                contact_info_values: contact_info_values,
                is_private
            }
        }, true);
    },
    deleteResourceByID: function(resource_id){
        return ApplicationAPI.apiRequest({
            url: `/resources/${resource_id}`,
            method: "DELETE",
            responseType: "json"
        }, true);
    },
    getResourceAssociatedCategoryData: function(resource_id){
        return ApplicationAPI.apiRequest({
            url: `/resources/${resource_id}/categories`
        }, false);
    },
    searchResources: function(query){
        return ApplicationAPI.apiRequest({
            url: `/resources`,
            method: "GET",
            params: {
                query: query
            }
        }, false);
    },
    getUsers: function(){
        return ApplicationAPI.apiRequest({
            url: `/users`,
            method: "GET",
        }, true);
    },
    getUserByID: function(user_id){
        return ApplicationAPI.apiRequest({
            url: `/users/${user_id}`,
            method: "GET",
        }, true);
    },
    getMyUser: function(){
        return ApplicationAPI.apiRequest({
            url: `/users/me`,
            method: "GET",
        }, true);
    },
    getEventTypes: function(){
        return ApplicationAPI.apiRequest({
            url: "/event-types",
            method: "GET"
        }, false);
    },
    getEventTypeByID: function(event_type_id){
        return ApplicationAPI.apiRequest({
            url: `/event-types/${event_type_id}`,
            method: "GET",
        }, false);
    },
    getEventsByEventTypeID: function(event_type_id, earliest_dt=null, latest_dt=null, limit=500){
        return ApplicationAPI.apiRequest({
            url: `/event-types/${event_type_id}/events`,
            method: "GET",
            params: {
                earliest_dt,
                latest_dt,
                limit
            }
        }, false);
    },
    getEvents: function(earliest_dt=null, latest_dt=null, limit=500){
        return ApplicationAPI.apiRequest({
            url: "/events",
            method: "GET",
            params: {
                earliest_dt,
                latest_dt,
                limit
            }
        }, false);
    },
    createEvent: function(event_type_id, event_title, event_description, event_start_dt, event_end_dt,
                          event_organizer="", event_url=null, event_location=null,
                          is_private=true, is_full_day_event=false, associated_resource_id=null){
        return ApplicationAPI.apiRequest({
            url: "/events",
            method: "POST",
            responseType: "json",
            body: {
                associated_event_type_id: event_type_id,
                event_title,
                event_description,
                event_organizer,
                event_start_dt,
                event_end_dt,
                event_url,
                event_location,
                is_private,
                is_full_day_event,
                associated_resource_id
            }
        }, true);
    },
    getEventByID: function(event_id){
        return ApplicationAPI.apiRequest({
            url: `/events/${event_id}`,
            method: "GET"
        }, false);
    },
    editEventByID: function(event_id, updated_data){
        return ApplicationAPI.apiRequest({
            url: `/events/${event_id}`,
            method: "PUT",
            responseType: "JSON",
            body: updated_data
        }, true);
    },
    removeEventByID: function(event_id){
        return ApplicationAPI.apiRequest({
            url: `/events/${event_id}`,
            method: "DELETE"
        }, true);
    },
    createUser: function(username, user_display_name, email_address, password, invitation_code){
        return ApplicationAPI.apiRequest({
            url: "/users",
            method: "POST",
            responseType: "json",
            body: {
                username,
                user_display_name,
                email_address,
                password,
                invitation_code
            }
        }, false);
    },
    createUserInvitation: function(email_addresses){
        return ApplicationAPI.apiRequest({
            url: "/users/invitations",
            method: "POST",
            responseType: "json",
            body: {
                email_addresses
            }
        }, true);
    },
    requestPasswordReset: function(username_or_email_address, captcha_value, captcha_token){
        return ApplicationAPI.apiRequest({
            url: "/users/requestResetPassword",
            method: "POST",
            responseType: "json",
            body: {
                usernameOrEmailAddress: username_or_email_address,
                captchaValue: captcha_value,
                captchaToken: captcha_token
            }
        }, false, "/v-1");
    },
    completePasswordReset: function(user_id, reset_code, new_password){
        return ApplicationAPI.apiRequest({
            url: `/users/${user_id}/resetPassword`,
            method: "POST",
            responseType: "json",
            body: {
                resetCode: reset_code,
                newPassword: new_password
            }
        }, false, "/v-1");
    },
    createExtUser: function(username, userDisplayName, emailAddress, registrationCode){
        return ApplicationAPI.apiRequest({
            url: '/users/ext',
            method: "POST",
            responseType: "json",
            body: {
                username, userDisplayName, emailAddress, registrationCode
            }
        }, false, "/v-1");
    }
};
