import m from "mithril";
import Stream from "mithril/stream/stream";
import {Input} from "./Input";

import {split} from "../Utils";
import {hideModal, showModal} from "./AppModal";

export function navigateToSearchPage(searchStringStream){
    if(searchStringStream()){
        return m.route.set(
        "/search/results",
        {query: searchStringStream()}
        );
    }
}

export function showSearchTips(){
    return showModal(
        m(".modal-card",
            m("header.modal-card-head",
                m("p.modal-card-title", "Search Tips"),
                m("button.delete", {"aria-label": "close", onclick(){ hideModal(); }})
            ),
            m("section.modal-card-body",
                m("p",
                    "Using the search component is similar to how you might create a Google search. " +
                    "If multiple words are included in the search input, the system will attempt to find " +
                    "results that optimally contain all of the words. Example: ",
                    m("pre.block", "Dentistry Denver"),
                    " would run a search for resources that are related to Dentistry, and contain the word Denver " +
                    " somewhere, like in the address or resource name. " +
                    "There are several operators you can use to help your search. Anything in quotes, " +
                    "such as:",
                    m("pre.block", '"Dental Center"'),
                    " will attempt to match resources containing the phrase 'Dental Center' exactly. Next, if the word OR is " +
                    "used, the search will find anything that matches multiple terms. Example: ",
                    m("pre.block", 'Dogs OR Cats'),
                    " would include results from the search for 'Dogs' and the search for 'Cats'. Finally " +
                    " if a minus sign is used in front of a term or quoted phrase, results matching that will be " +
                    "ignored. Example: ",
                    m("pre.block", 'Dentistry -"Aurora"'),
                    " would find results for 'Dentistry' but would exclude any results that include the word 'Aurora'."
                )
            )
        )
    );
}


export const SearchWidget = () => {
  // Local state ...
    return {
        view: (vnode) => {
            let [searchStringStream, attrs1] = split(vnode.attrs, "searchStringStream", Stream());
            let [onSearch, attrs2] = split(attrs1, "onSearch", function(searchStringStream){});
            return m(".search-widget", {...attrs2},
                m(Input, {
                    inputStream: searchStringStream,
                    label: "Search",
                    oncreate(vnode){
                       vnode.dom.addEventListener(
                          "keydown", e => {
                              if(e.code === "Enter"){
                                  onSearch(searchStringStream);
                              }
                           }
                       );
                    }
                }),
                m("button.button.is-link.is-fullwidth.mb-2",
                  {
                      onclick(){
                          onSearch(searchStringStream);
                      }
                  },
                  m("span.icon", m("i.fa.fa-magnifying-glass")),
                  m("span", "Search")
                ),
                m("a.is-size-6.is-italic",
                    {
                        onclick(){
                            showSearchTips();
                        }
                    },
                    "Search Tips"
                )
            );
        }
    };
};
