export const CoreServiceConfig = {
    _baseUrl: '',
    _defaultTimeout: 2000,

    set baseUrl(value){
        this._baseUrl = value;
    },

    get baseUrl(){
        if(!this._baseUrl){
            this._baseUrl = `${window.location.protocol}//${window.location.host}`;
        }
        return this._baseUrl;
    },

    currentTime(){
        return (new Date()).getTime()/1000;
    },

    get timeout(){
        return this._defaultTimeout;
    },

    calculateUrl(path){
        let baseUrl = this.baseUrl;
        if(baseUrl.endsWith('/')) return `${this.baseUrl.substring(0, baseUrl.length - 1)}${path}`;
        else return `${this.baseUrl}${path}`;
    }
};