import '@fortawesome/fontawesome-free/css/all.css';

import m from "mithril";

export let Icons = {
    AngleDown: {
        view: function(vnode) {
            return m('span.icon', { ...vnode.attrs },
                m("i.fas.fa-solid.fa-angle-down", { ...vnode.attrs }, vnode.children));
        }
    },
    AngleUp: {
        view(vnode) {
            return m('span.icon', {...vnode.attrs},
                m("i.fas.fa-solid.fa-angle-up", {...vnode.attrs}, vnode.children));
        }
    }
}