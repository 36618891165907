import m from "mithril";
import {AuthAPI, Auth} from "./services/Auth";

let hasOwn = {}.hasOwnProperty;

export function split(obj, key, default_value=null){
    if(obj.hasOwnProperty(key)){
        const {[key]: value, ...newObj} = obj;
        return [value, newObj];
    } else {
        return [default_value, obj];
    }
}

export function get(obj, key, default_value=null){
    if(obj.hasOwnProperty(key)){
        return obj[key];
    } else {
        return default_value;
    }
}

export function censor(attrs, exclude) {
    for (let i = 0; i < exclude.length; i++) {
        if (hasOwn.call(attrs, exclude[i])) {
            let result = {};

            for (let key in attrs) {
                if (hasOwn.call(attrs, key) && !exclude.includes(key)) {
                    result[key] = attrs[key];
                }
            }

            return result;
        }
    }
    return attrs;
}

export function boolAttrsFix(attrs){
    let result = {};
    for(let key in attrs){
        if(hasOwn.call(attrs, key) && attrs[key] === true){
            result[key] = "true";
        }
        else if(hasOwn.call(attrs, key) && attrs[key] === false){
            // skip
        }
        else if(hasOwn.call(attrs, key)){
            result[key] = attrs[key];
        }
    }
    return result;
}

export function performAuthCheck(must_be_logged_in=false){
    if(Auth.needsAuthCheck()){
        AuthAPI.checkLogin().then(result => {
            if(!result && must_be_logged_in){
                m.route.set("/login");
            }
        }).catch(err => {
            m.route.set("/login");
        });
    }
    else if(!Auth.isAuthenticated && must_be_logged_in){
        m.route.set("/login");
    }
}


export function streamUnmerge(stream){
    if(stream()){
        return stream().map((val, index) => stream.map(arr => arr[index]));
    } else {
        return [];
    }
}

export function trimIfString(value) {
    if (typeof value === 'string') {
        return value.trim();
    } else {
        return value;
    }
}

export function unsafeCalculateMithrilLink(subpath){
    let currentUrl = window.location;
    return `${currentUrl.protocol}//${currentUrl.host}/${m.route.prefix}${subpath}`;
}

export function cleanUrl(url){
    if(/(http:\/\/|https:\/\/)/.test(url)){
        return url;
    } else {
        return `https://${url}`;
    }
}