import m from "mithril";
import Stream from "mithril/stream/stream";

import {split} from "../Utils";
import {ListBox, ListItem} from "./List";
import {ApplicationAPI} from "../services/ApplicationAPI";
import {showErrorModal} from "./AppModal";


const ResourceSearchListItem = (initialNode) => {
    return {
        view: (vnode) => {
            let [resource, attrs1] = split(vnode.attrs, "resource");
            let [categoryMapSteam, attrs2] = split(attrs1, "categoryMapStream", Stream());
            return m(ListItem, {
                title: m(m.route.Link, {href: `/resources/${resource.resource_id}`}, resource.resource_name),
                key: resource.resource_id,
                contentStream: Stream(
                    m(".info-block",
                        categoryMapSteam.map(catMap =>
                            m(".info",
                                `Category: ${catMap[resource.primary_category_id].resource_category_name}`)
                        )() || null,
                        m(".info", "Tags:",
                            m(".tags.are-small", resource.tags.map(tag => m("span.level-item.tag.is-info", tag)))
                        )
                    )
                ), ...attrs2
            });
        }
    };
};


export const ResourceSearchList = (initialVNode) => {
    let state = {
        categoryMapStream: Stream()
    };
    return {
        oninit: () => {
            ApplicationAPI.getCategories().then(categories => {
                state.categoryMapStream(
                    categories.reduce((a, cat) => ({
                        ...a, [cat.resource_category_id]: cat
                    }), {})
                );
            }).catch(error => showErrorModal(error));
        },
        view: (vnode) => {
            let [resourcesStream, attrs] = split(vnode.attrs, "resourcesStream", Stream());
            if(resourcesStream() && resourcesStream().length > 0) {
                return m(ListBox, {...attrs},
                    resourcesStream().map(res => {
                      return m(ResourceSearchListItem, {
                          resource: res,
                          categoryMapStream: state.categoryMapStream
                      });
                    })
                );
            } else {
                return m(".block.is-italic.m-1.p-1", "No Results");
            }
        }
    };
};