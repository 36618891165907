import m from "mithril";
import {AppNavbar} from "./AppNavbar";
import {AppFooter} from "./AppFooter";
import {getModal, hideModal} from "./AppModal";

export const AppPageContainer = () => {
  // Local state ...
  return {
    oninit(){
      hideModal();
    },
    view: (vnode) => {
      return m(".page",
          {...vnode.attrs},
          m(AppNavbar),
          vnode.children,
          getModal(),
          m(AppFooter)
      );
    },
  };
};
