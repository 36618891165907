import m from 'mithril';

import {split} from "../Utils";

export let Card = {
    view: function(vnode) {
        return m('.card', { ...vnode.attrs }, vnode.children);
    },

    Header: {
        view: function(vnode) {
            let [header_children, attrs] = split(vnode.attrs, "header_children");
            return m("header.card-header", { ...attrs }, [
                m("p.card-header-title", vnode.children),
                header_children
            ]);
        }
    },

    Image: {
        view: function(vnode) {
            return m(".card-image", vnode.attrs, vnode.children);
        }
    },

    Content: {
        view: function(vnode) {
            return m(".card-content", { ...vnode.attrs }, vnode.children);
        }
    },

    Footer: {
        view: function(vnode) {
            return m("footer.card-footer", { ...vnode.attrs }, vnode.children);
        }
    },

    FooterItem: {
        view: function(vnode) {
            return m("a.card-footer-item", { ...vnode.attrs }, vnode.children);
        }
    }
};
