import m from "mithril";
import Stream from "mithril/stream/stream";

import {ApplicationAPI} from "../services/ApplicationAPI";
import {performAuthCheck} from "../Utils";
import {AppPageContainer} from "../components/AppPageContainer";
import Container from "mithril-bulma/components/layout/Container";
import Title from "mithril-bulma/components/Title";
import Box from "mithril-bulma/components/Box";
import {ResourceSearchList} from "../components/ResourceSearchList";
import {SearchWidget, navigateToSearchPage} from "../components/SearchWidget";

export const SearchResultsPage = () => {
  let state = {
      searchString: Stream(m.route.param("query")),
      searchResultsStream: Stream()
  };

  function runSearch(){
      if(state.searchString()){
          ApplicationAPI.searchResources(state.searchString()).then(results => state.searchResultsStream(results));
      }
  }

  return {
    oninit: () => {
        performAuthCheck();
        runSearch();
    },
    view: () => {
      return m(AppPageContainer, [
          m(Container,
              m(Title, {is: "4", class: "m-1 p-1"}, "Search Results"),
              m(Box,
                m(SearchWidget, {
                    searchStringStream: state.searchString,
                    onSearch(searchStringStream){
                        navigateToSearchPage(searchStringStream);
                        runSearch();
                    }
                }),
                m("hr"),
                m(ResourceSearchList, {
                    resourcesStream: state.searchResultsStream
                })
              )
          )
      ]);
    },
  };
};
