import m from "mithril";
import Stream from "mithril/stream/stream";

import {Icons} from "./Icons";
import {Card} from "./BulmaCard";
import {Markdown, MarkdownV2} from "./Markdown";

import {split} from "../Utils";


export const DescriptionCollapse = (initialVnode) => {
  let state = {
      shown: true,
  };
  return {
    view: (vnode) => {
        let [descriptionStream, attrs1] = split(vnode.attrs, "descriptionStream", Stream());
        let [useMarkdown, attrs2] = split(attrs1, "useMarkdown", true);
        return m(Card,
            {...attrs2},
            m(Card.Header,
                {
                    onclick(){ state.shown = !state.shown; },
                    header_children:  m("button.card-header-icon", {"aria-label": "Expand"},
                        state.shown? m(Icons.AngleUp) : m(Icons.AngleDown)
                    )
                },
                "Description",
            ),
            state.shown && descriptionStream() ? m(Card.Content,
                useMarkdown? m(MarkdownV2.MarkdownViewer, {contentStream: descriptionStream}) : descriptionStream()
                ) : null
        );
    },
  };
};
