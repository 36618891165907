import m from "mithril";
import DOMPurify from "dompurify";
import {marked} from "marked";
import EasyMDE from "easymde";
import "easymde/dist/easymde.min.css";


export const Markdown = () => {
  // Local state ...
  return {
    view: (vnode) => {
      if(vnode.attrs.contentStream()){
        return m(".content", m.trust(DOMPurify.sanitize(marked.parse(vnode.attrs.contentStream()))));
      } else {
        return null;
      }
    },
  };
};

export const MarkdownV2 = {
  MarkdownViewer(initialVnode){
    return {
      view: (vnode) => {
        if(vnode.attrs.contentStream()){
          return m(".content", m.trust(DOMPurify.sanitize(marked.parse(vnode.attrs.contentStream()))));
        } else {
          return null;
        }
    },
    };
  },
  MarkdownEditor(initialVnode){
    let editor;
    let contentStream;
    return {
      oncreate(vnode){
        contentStream = vnode.attrs.contentStream;
        editor = new EasyMDE({
          autoDownloadFontAwesome: false,
          element: vnode.dom,
          initialValue: contentStream(),
          sanitizerFunction: DOMPurify.sanitize,
          spellChecker: false,
          nativeSpellcheck: false
        });
        editor.codemirror.on("change", function(){
            contentStream(editor.value());
        });
      },
      onupdate(vnode){
          contentStream = vnode.attrs.contentStream;
          editor.value(contentStream());
      },
      onremove(vnode){
          editor.cleanup();
      },
      view(vnode) {
        return m("textarea.textarea");
      }
    };
  }
};
