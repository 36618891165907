import m from "mithril";
import Stream from "mithril/stream/stream";

import {ApplicationAPI} from "../services/ApplicationAPI";
import {performAuthCheck} from "../Utils";
import Container from "mithril-bulma/components/layout/Container";
import Title from "mithril-bulma/components/Title";
import Box from "mithril-bulma/components/Box";
import {showErrorModal} from "../components/AppModal";
import {AppPageContainer} from "../components/AppPageContainer";
import {NonEmptyInput} from "../components/Input";
import {MarkdownV2} from "../components/Markdown";
import {PrivateSwitchInput} from "../components/PrivateSwitch";
import {SubmitCancelWidget} from "../components/SubmitCancelWidget";

export const EditCategoryPage = () => {
  let state = {
      category_id: parseInt(m.route.param("key")),
      category: Stream(null),
      category_name: Stream("???"),
      new_category_name: Stream(""),
      category_description: Stream(""),
      display_is_private: Stream(false),
      is_private: Stream(false)
  };

  return {
    oninit: () => {
        performAuthCheck(true);
        ApplicationAPI.getCategoryByID(state.category_id).then(result => {
            state.category(result);
            state.category_name(result.resource_category_name);
            state.new_category_name(result.resource_category_name);
            state.category_description(result.resource_category_description);
            state.display_is_private(result.is_private);
            state.is_private(result.is_private);
        }).catch(error => {
           showErrorModal(error);
        });
    },
    view: () => {
      return m(AppPageContainer, [
          state.category() ? m(Container,
              m(Title, {is: "4", class: "m-1 p-1"},
                 m("span", `Edit Category "${state.category_name()}"`)
              ),
              state.display_is_private()? m(
                  "span.tag.is-warning.is-medium.is-italic.ml-2.mt-1.mb-1.has-text-weight-normal", "Private"
              ) : null,
              m(Box,
                  m(NonEmptyInput, {
                      label: "Category Name",
                      inputStream: state.new_category_name
                  }),
                  m(PrivateSwitchInput, {
                      tooltip: "When set to private, this category and all of its resources will be " +
                                "hidden from any user that is not logged in.",
                      isPrivateStream: state.is_private
                  }),
                  m("div.field",
                    m("label.label", "Description"),
                    m(MarkdownV2.MarkdownEditor, {contentStream: state.category_description}),
                    m(".is-italic", "Description rendered as ",
                      m("a", {href: "https://www.markdownguide.org/basic-syntax/"}, "Markdown"))
                  ),
                  m(SubmitCancelWidget, {
                      onSubmit(e){
                          ApplicationAPI.editCategoryByID(
                              state.category_id,
                              state.new_category_name(),
                              state.category_description(),
                              state.is_private()
                          ).then(result => {
                              m.route.set(`/resource-categories/${result.resource_category_id}`);
                          }).catch(err => {
                              showErrorModal(err);
                          });
                      },
                      onCancel(e){
                          window.history.go(-1);
                      }
                  })
              )
          ) : m(".block.is-italic.m-1.pt-3.has-text-centered", "Category Not Found")
      ]);
    },
  };
};
