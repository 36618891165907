import m from "mithril";
import Stream from "mithril/stream/stream";

import {performAuthCheck} from "../Utils";
import {AppPageContainer} from "../components/AppPageContainer";
import Container from "mithril-bulma/components/layout/Container";
import Title from "mithril-bulma/components/Title";
import Box from "mithril-bulma/components/Box";
import {SearchWidget, navigateToSearchPage} from "../components/SearchWidget";

export const SearchPage = () => {
  let state = {
      searchString: Stream(m.route.param("query")),
      searchResultsStream: Stream()
  };

  return {
    oninit: () => {
        performAuthCheck();
    },
    view: () => {
      return m(AppPageContainer, [
          m(Container,
              m(Title, {is: "4", class: "m-1 p-1"}, "Search"),
              m(Box,
                m(SearchWidget, {
                    searchStringStream: state.searchString,
                    onSearch(searchStringStream){
                        navigateToSearchPage(searchStringStream);
                    }
                })
              )
          )
      ]);
    },
  };
};
