import m from "mithril";
import {getModal, hideModal} from "./AppModal";
import Hero from "mithril-bulma/components/layout/Hero";
import Container from "mithril-bulma/components/layout/Container";
import Columns from "mithril-bulma/components/layout/Columns";
import Title from "mithril-bulma/components/Title";
import Notification from "mithril-bulma/components/Notification";
import Delete from "mithril-bulma/components/Delete";
import {ApplicationAPI} from "../services/ApplicationAPI";
import {censor, get} from "../Utils";
import {CoreServiceConfig} from "../services/Core";

let notifications = [];

export function addNotification(text, type="warning"){
    notifications.push({text, type});
}

export function clearNotifications(){
    notifications = [];
}

export const AuthPageContainer = (initialVnode) => {
  return {
    oninit(vnode){
      hideModal();
    },
    view: (vnode) => {
      return m(Hero,
            {
                class: "is-primary is-fullheight is-link",
                ...censor(vnode.attrs, ['title'])
            },
            m(Hero.Body,
                m(Container,
                  m(Columns,
                      {
                          class: "is-centered"
                      },
                      m(Columns.Column,
                          {
                              is: "5-tablet 5-desktop 4-widescreen"
                          },
                          m(Title,
                            m("img",
                                {
                                    style: "object-fit: contain; background-color: white; border-radius: 5px;",
                                    src: `${CoreServiceConfig.calculateUrl('/images/ah-logo.png')}`, alt: "Almost Home Inc.",
                                    id: 'auth-page-logo-img'
                                }
                            ),
                            get(vnode.attrs, "title","Resource App Login")
                          ),
                          m("form", {class: "box"},
                              notifications.map((n, i) => {
                                  return m(Notification,
                                      {
                                          is: n.type,
                                      },
                                      m(Delete,
                                          {
                                              onclick(e){
                                                  e.preventDefault();
                                                  notifications.splice(i, 1);
                                              }
                                          }
                                      ),
                                      n.text
                                  );
                              }),
                              vnode.children
                          )
                      )
                  )
                )
            )
        );
    },
  };
};