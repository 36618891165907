import m from "mithril";
import Stream from "mithril/stream/stream";

import Box from "mithril-bulma/components/Box";
import {Input, MultiInput, NonEmptyInput, SelectInput, TagsInput} from "./Input";
import {split} from "../Utils";
import {MarkdownV2} from "./Markdown";
import {PrivateSwitchInput} from "./PrivateSwitch";
import {SubmitCancelWidget} from "./SubmitCancelWidget";


export function newResourceStateStreams(resource=null){
    if(resource) {
        return {
            resource_name: Stream(resource.resource_name),
            resource_description: Stream(resource.resource_description),
            primary_category_id: Stream(resource.primary_category_id),
            resource_location: Stream(resource.resource_location),
            tags: Stream(resource.tags),
            url_values: Stream(resource.url_values),
            contact_info_values: Stream(resource.contact_info_values),
            is_private: Stream(resource.is_private)
        };
    } else {
        return {
            resource_name: Stream(""),
            resource_description: Stream(""),
            primary_category_id: Stream(null),
            resource_location: Stream(""),
            tags: Stream([]),
            url_values: Stream([]),
            contact_info_values: Stream([]),
            is_private: Stream(false)
        };
    }
}


export const ResourceWriteWidget = (initialVnode) => {
  return {
    view: (vnode) => {
        let [resourceStateStreams, attrs1] = split(vnode.attrs, "resourceStateStreams", newResourceStateStreams());
        let [categories, attrs2] = split(attrs1, "categories", Stream());
        let [onSubmit, attrs3] = split(attrs2, "onSubmit", function(event, resourceStateStreams){});
        let [onCancel, attrs4] = split(attrs3, "onCancel", function(event){ window.history.go(-1); });

        return m(Box, {...attrs4},
            m(NonEmptyInput, {
                inputStream: resourceStateStreams.resource_name,
                label: "Resource Name"
            }),
            m(PrivateSwitchInput, {
                  tooltip: "When set to private, this resource will be " +
                            "hidden from any user that is not logged in.",
                  isPrivateStream: resourceStateStreams.is_private
            }),
            m("div.field",
              m("label.label", "Description"),
              m(MarkdownV2.MarkdownEditor, {contentStream: resourceStateStreams.resource_description}),
              m(".is-italic", "Description rendered as ",
                  m("a", {href: "https://www.markdownguide.org/basic-syntax/"}, "Markdown"))
            ),
            m(SelectInput, {
                optionStream: categories,
                label: "Primary Category",
                getValueDataID(cat){ return cat.resource_category_id; },
                getValueRepr(cat){ return `${cat.resource_category_name}${cat.is_private? " (Private)": ""}`; },
                selectedStream: resourceStateStreams.primary_category_id
            }),
            m(Input, {
                inputStream: resourceStateStreams.resource_location,
                label: "Location",
            }),
            m(MultiInput, {
               inputsStream: resourceStateStreams.url_values,
               label: "URL",
               labelPlural: "URLs",
               inputType: "url"
            }),
            m(MultiInput, {
               inputsStream: resourceStateStreams.contact_info_values,
               label: "Contact Info",
               inputType: "text"
            }),
            m(TagsInput, {
                inputsStream: resourceStateStreams.tags,
                label: "Tag",
                labelPlural: "Tags",
                placeholder: "New Tags"
            }),
            m("div.field.is-grouped",
              m("div.control.is-expanded",
                  m("button.button.is-link.is-fullwidth",
                      {
                          onclick(e){
                              return onSubmit(e, resourceStateStreams);
                          }
                      },
                      "Submit")
              ),
              m("div.control.is-expanded",
                  m("button.button.is-link.is-light.is-fullwidth",
                      {
                          onclick(e){
                              return onCancel(e);
                          }
                      },
                      "Cancel")
              )
            )
        );
    },
  };
};
