import m from 'mithril'

export let BulmaTabs = {
    view: function(vnode) {
        return m(`.tabs`, { ...vnode.attrs }, m("ul", vnode.children))
    },

    Item: {
        view: function(vnode) {
            return m('li', { ...vnode.attrs }, m("a", { ...vnode.attrs }, vnode.children))
        }
    }
}
