import m from "mithril";
import Stream from "mithril/stream/stream";

import {split, censor, get, boolAttrsFix} from "../Utils";
import select from "mithril-bulma/components/input/Select";

function inputBase(inputStream, label, extraFieldChildren, fieldAttrs, actualAttrs,
                   inputType = "text", inputValidator=function(val, label){ return null; },
                   suppressLabel = false){
    let validationMessage = inputValidator(inputStream(), label);
    let realAttrs = boolAttrsFix(censor(actualAttrs, ['oninput']));
    return m("div.field", {...fieldAttrs},
      !suppressLabel? m("label.label", label): null,
      m("input.input" + (validationMessage !== null ? ".is-danger" : ""),
          {
              type: inputType, placeholder: label, value: inputStream(),
              oninput(e){
                  inputStream(this.value);
                  get(actualAttrs, 'oninput', function(e){})(e);
              },
              ...realAttrs
          }),
        validationMessage !== null ? m(
          "p.help.is-danger", validationMessage
        ): null,
        extraFieldChildren
    );
}

function horizontalInputBase(inputStream, label, extraFieldChildren, fieldAttrs, actualAttrs,
                             inputType = "text", inputValidator=function(val, label){ return null; },
                             suppressLabel = false){
    let validationMessage = inputValidator(inputStream(), label);
    let realAttrs = boolAttrsFix(censor(actualAttrs, ['oninput']));
    return m("div.field.is-horizontal", {...fieldAttrs},
      m(".field-label.is-normal", !suppressLabel? m("label.label", label): null,),
      m(".field-body", m(".field",
        m(".control", m("input.input" + (validationMessage !== null ? ".is-danger" : ""),
          {
              type: inputType, placeholder: label, value: inputStream(),
              oninput(e){
                  inputStream(this.value);
                  get(actualAttrs, 'oninput', function(e){})(e);
              },
              ...realAttrs
        })),
        validationMessage !== null ? m(
          "p.help.is-danger", validationMessage
        ): null,
        extraFieldChildren
      ))
    );

}

export function NullValidator(val, label){ return null; }

export function NonEmptyValidator(val, label){
    if(!val || val.trim() === ""){
        return `${label} must not be empty.`;
    } else {
        return null;
    }
}

export function BasicEmailValidator(val, label){
    if(!val || !/^\S+@\S+\.\S+$/.test(val.trim())){
        return `${label} is not a valid email address.`;
    } else {
        return null;
    }
}

export function URLValidator(val, label){
    if(!val || !/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/.test(val.trim())){
        return `${label} is not a valid URL.`;
    }
    else {
        return null;
    }
}

export function CombineValidators(...validators){
    return function(val, label){
        for(let validator of validators){
            let result = validator(val, label);
            if(result !== null){
                return result;
            }
        }
        return null;
    };
}



export const ToggleInput = () => {
  return {
    view: (vnode) => {
      let inputStream = get(vnode.attrs, "inputStream", Stream(false));
      let label = get(vnode.attrs, "label", "");
      let name = get(vnode.attrs, "name", "toggle-input");
      return m("div.field",
          m("input.switch",
              {
                  checked: inputStream()? "checked": "",
                  type: "checkbox",
                  name,
                  onchange(e) {
                    inputStream(!inputStream());
                  },
                  ...censor(vnode.attrs, ["inputStream", "label", "name"])
              }
          ),
          m("label.label", {for: name}, label)
      );
    },
  };
};


export const Input = () => {
  return {
    view: (vnode) => {
        let actualAttrs = censor(
            vnode.attrs,
            ["inputStream", "label", "extraFieldChildren", "inputType", "fieldAttrs", "suppressLabel",
                     "isHorizontal"]
        );
        let inputFunction = get(vnode.attrs, "isHorizontal", false)? horizontalInputBase : inputBase;
        return inputFunction(
            get(vnode.attrs, "inputStream", Stream("")),
            get(vnode.attrs, "label", "???"),
            get(vnode.attrs, "extraFieldChildren", []),
            get(vnode.attrs, "fieldAttrs", {}),
            actualAttrs,
            get(vnode.attrs, "inputType", "text"),
            get(vnode.attrs, "inputValidator", NullValidator),
            get(vnode.attrs, 'suppressLabel', false)
        );
    },
  };
};

export const NonEmptyInput = () => {
  return {
    view: (vnode) => {
        let actualAttrs = censor(
            vnode.attrs,
            ["inputStream", "label", "extraFieldChildren", "inputType", "fieldAttrs", "suppressLabel",
                     "isHorizontal"]
        );
        let inputFunction = get(vnode.attrs, "isHorizontal", false)? horizontalInputBase : inputBase;
        return inputFunction(
            get(vnode.attrs, "inputStream", Stream("")),
            get(vnode.attrs, "label", "???"),
            get(vnode.attrs, "extraFieldChildren", []),
            get(vnode.attrs, "fieldAttrs", {}),
            {required: true, ...actualAttrs},
            get(vnode.attrs, "inputType", "text"),
            CombineValidators(NonEmptyValidator, get(vnode.attrs, "inputValidator", NullValidator)),
            get(vnode.attrs, 'suppressLabel', false)
        );
    },
  };
};

export const SelectInput = () => {
    return {
      view: (vnode) => {
        let [optionStream, attrs1] = split(vnode.attrs, "optionStream", Stream([]));
        let [label, attrs2] = split(attrs1, "label", "???");
        let [extraFieldChildren, attrs3] = split(attrs2, "extraFieldChildren", []);
        let [getValueDataID, attrs4] = split(attrs3, "getValueDataID", function(value){ return value.id; })
        let [getValueRepr, attrs5] = split(attrs4, "getValueRepr", function(value){ return value; });
        let [selectedStream, attrs6] = split(attrs5, "selectedStream", Stream(null));
        let [fieldAttrs, actualAttrs] = split(attrs6, "fieldAttrs", {});

        return m("div.field", {...fieldAttrs},
            m("label.label", label),
            m(".control.is-expanded", m(".select.is-fullwidth",
                m("select",
                    {onchange(e){
                        selectedStream(e.target.value);
                    }, ...actualAttrs},
                    optionStream.map(values => {
                        if((selectedStream() === undefined || selectedStream() === null) && values.length > 0){
                            // TODO: This still isn't optimal...
                            selectedStream(getValueDataID(values[0]));
                        }
                        return values.map(value =>{
                            let valueID = getValueDataID(value);
                            return m("option",
                                {value: valueID, selected: (selectedStream() === valueID? true: null) },
                                getValueRepr(value)
                            );
                        });
                    })()
                )
            )),
            extraFieldChildren
        );
      }
    };
};

export const MultiInput = () => {
    return {
        view: (vnode) => {
          let inputsStream = get(vnode.attrs, "inputsStream", Stream([]));
          let label = get(vnode.attrs, "label", "???");
          let inputValidator = get(vnode.attrs, "inputValidator", NullValidator);
          let actualAttrs = censor(
              vnode.attrs, [
                  "inputsStream", "label", "extraFieldChildren", "fieldAttrs", "inputType", "labelPlural"
              ]
          );
          let inputValues = inputsStream();
          return m("div.field", {...get(vnode.attrs, "fieldAttrs", {})},
            m("label.label", get(vnode.attrs, "labelPlural", label)),
            inputValues.map((val, index) => {
                let validationMessage = inputValidator(val, label);
                return m("div.field",
                    m("div.field.has-addons",
                        m("div.control.is-expanded",
                            m("input.input" + (validationMessage !== null ? ".is-danger" : ""),
                                {
                                    type: get(vnode.attrs, "inputType", "text"),
                                    value: val,
                                    placeholder: label,
                                    key: `mi-${index}`,
                                    onchange(){
                                        inputValues.splice(index, 1, this.value);
                                        inputsStream(inputValues);
                                    },
                                    ...actualAttrs
                                }
                            ),
                        ),
                        m("div.control",
                            m("button.button.is-danger",
                                {onclick(){
                                    inputValues.splice(index, 1);
                                    inputsStream(inputValues);
                                }},
                                m("i.fa.fa-trash")
                            )
                        )
                    ),
                    validationMessage !== null ? m(
                      "p.help.is-danger", validationMessage
                    ): null,
                );
            }),
            m(".control.is-expanded",
                m("button.button.is-primary.is-fullwidth",
                    {
                        onclick(){
                            inputValues.push("");
                            inputsStream(inputValues);
                        }
                    },
                    m("span.icon", m("i.fa.fa-plus")),
                    m("span", `Add ${label}`)
                )
            ),
            get(vnode.attrs, "extraFieldChildren", [])
          );
        }
    };
};

export const TagsInput = () => {
    let state = {
        currentInput: ""
    };

    function processTags(inputsStream){
        let values = state.currentInput.split(",");
        let newTags = values.slice(0, -1).map(tag => tag.trim());
        let tags = inputsStream().concat(newTags);
        tags = tags.filter((tag, index) => {
           return tags.indexOf(tag) === index;
        });
        inputsStream(tags);
        state.currentInput = values.slice(-1)[0].trim();
    }

    return {
        view: (vnode) => {
          let [inputsStream, attrs1] = split(vnode.attrs, "inputsStream", Stream([]));
          let [label, attrs2] = split(attrs1, "label", "???");
          let [extraFieldChildren, attrs3] = split(attrs2, "extraFieldChildren", []);
          let [fieldAttrs, attrs4] = split(attrs3, "fieldAttrs", {});
          let [labelPlural, attrs5] = split(attrs4, "labelPlural", label);
          let [placeholder, actualAttrs] = split(attrs5, "placeholder", labelPlural);
          let inputValues = inputsStream();

          return m("div.field", {...fieldAttrs},
            m("label.label", `${labelPlural} (end with comma)`),
            m(".tags",
                inputValues.map((val, index) => {
                   return m("span.tag.is-info.is-medium",
                       val,
                       m("button.delete.is-medium",
                           {onclick(){
                                inputValues.splice(index, 1);
                                inputsStream(inputValues);
                           }}
                       )
                   );
                }),
            ),
            m("input.input", {
                  type: "text", placeholder: placeholder, value: state.currentInput,
                  onchange(){
                      state.currentInput = this.value;
                      processTags(inputsStream);
                  },
                  ...actualAttrs
            }),
            extraFieldChildren
          );
        }
    };
};