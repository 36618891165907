import m from "mithril";

import {split} from "../Utils";

export const ResourceList = (initialVNode) => {
    return {
        view: (vnode) => {
            let [resourcesStream, someAttrs] = split(vnode.attrs, "resourcesStream");
            let [filterValue, attrs] = split(someAttrs, "filterValue");
            if(resourcesStream() && resourcesStream().length > 0) {
                return m(".list", {...attrs},
                    resourcesStream().filter(
                        res => filterValue === null || res.resource_name.toLowerCase().includes(filterValue.toLowerCase())
                    ).map(res => {
                      return m("a.list-item", {
                              onclick(){ m.route.set(`/resources/${res.resource_id}`);},
                              ...attrs
                          },
                          m(".list-item-title",
                              {...attrs}, res.resource_name,
                              res.is_private?
                                  m("span.tag.is-warning.is-small.is-italic.ml-2.mt-1.mb-1.has-text-weight-normal",
                                      "Private"
                                  ): null
                          )
                      );
                    })
                );
            } else {
                return m(".block.is-italic.m-1.p-1", "None Yet");
            }
        }
    };
};