import m from "mithril";
import Stream from "mithril/stream/stream";

import {ApplicationAPI} from "../services/ApplicationAPI";
import {performAuthCheck} from "../Utils";
import {AppPageContainer} from "../components/AppPageContainer";
import Container from "mithril-bulma/components/layout/Container";
import Title from "mithril-bulma/components/Title";
import Box from "mithril-bulma/components/Box";
import {Input, NonEmptyInput, BasicEmailValidator} from "../components/Input";
import {showErrorModal} from "../components/AppModal";
import {Auth} from "../services/Auth";


export const ExtNewUserPage = () => {
  let state = {
      registrationCode: m.route.param("registrationCode"),
      givenUsername: m.route.param("givenUsername"),
      givenUserDisplayName: (m.route.param("givenUserDisplayName") || '').replace(/\+/g, ' '),
      givenEmailAddress: m.route.param("givenEmailAddress"),
      username: Stream(m.route.param("givenUsername")),
      userDisplayName: Stream((m.route.param("givenUserDisplayName") || '').replace(/\+/g, ' ')),
      emailAddress: Stream(m.route.param("givenEmailAddress"))
  };

  function canSubmit(){
      return (
             state.givenEmailAddress || (
                 state.emailAddress() && BasicEmailValidator(state.emailAddress(), 'Email Address') == null)
          ) &&
          state.username() && state.userDisplayName() && state.registrationCode;
  }

  return {
    oninit: () => {
        performAuthCheck();
    },
    view: () => {
      return m(AppPageContainer, [
          m(Container,
              m(Title, {is: "3", class: "m-1 p-1"}, "Complete Your Registration"),
              m(Box,
                  m(NonEmptyInput, {
                      inputStream: state.username,
                      label: "Username",
                      disabled: !!(state.givenUsername)
                  }),
                  m(NonEmptyInput, {
                      inputStream: state.userDisplayName,
                      label: "Real Name",
                      disabled: !!(state.givenUserDisplayName)
                  }),
                  m(NonEmptyInput, {
                      inputStream: state.emailAddress,
                      label: "Email Address",
                      inputType: "email",
                      inputValidator: BasicEmailValidator,
                      disabled: !!(state.givenEmailAddress)
                  }),
                  m("button.button.is-link.is-fullwidth",
                    {
                        disabled: !canSubmit(),
                        onclick(){
                            Auth.clearAuthData();
                            ApplicationAPI.createExtUser(
                                state.username(),
                                state.userDisplayName(),
                                state.emailAddress(),
                                state.registrationCode
                            ).then(res => {
                                Auth.setSavedJwt(res.token);
                                Auth.setIsAuthenticated(true);
                                return res;
                            }).then(res => m.route.set("/")).catch(error => {
                                showErrorModal(error);
                            });
                        }
                    },
                    m("span.icon", m("i.fa.fa-user-plus")),
                    m("span", "Create User")
                )
              )
          )
      ]);
    },
  };
};
