export let almostHomeLinks = [
    {
        name: "Housing Navigation Center Welcome Survey",
        url: "https://docs.google.com/forms/d/e/1FAIpQLSfNYcfkISc4P5WkiZLnlorAeBN8mBi_KlpJUhSbJbWVKJdGcg/viewform"
    },
    {
        name: "Apply for Homeless Prevention Assistance",
        url: "https://forms.office.com/r/9DAzBh9uS8"
    },
    {
        name: "Apply for Emergency Family Shelter",
        url: "https://forms.office.com/r/4TdBMX2i8F"
    }
];

export let buildType = __BUILD_TYPE__ || 'prod';
export let isDemo = buildType === 'demo';