import m from "mithril";
import Stream from "mithril/stream/stream";

import {ApplicationAPI} from "../services/ApplicationAPI";
import {ResourceList} from "../components/ResourceList";
import {DescriptionCollapse} from "../components/DescriptionCollapse";

import Title from "mithril-bulma/components/Title";
import Container from "mithril-bulma/components/layout/Container";
import Subtitle from "mithril-bulma/components/Subtitle";
import Box from "mithril-bulma/components/Box";
import {performAuthCheck} from "../Utils";
import Columns from "mithril-bulma/components/layout/Columns";
import {showDeleteModal, showErrorModal} from "../components/AppModal";
import {AppPageContainer} from "../components/AppPageContainer";
import {Auth} from "../services/Auth";


export const CategoryPage = () => {
  let state = {
      category_id: parseInt(m.route.param("key")),
      category: Stream(),
      resources: Stream()
  };

  function isPrivate(){
      return state.category()? state.category().is_private: false;
  }

  function triggerDeleteModal(){
      showDeleteModal(
          `Delete Category '${state.category().resource_category_name}'?`,
          `Are you sure you want to delete category '${state.category().resource_category_name}'?`,
          function(callback){
              ApplicationAPI.deleteCategoryByID(state.category_id).then(res => {
                  callback();
                  m.route.set("/");
              }).catch(error => {
                  showErrorModal(error);
              });
          }
      );
  }


  function editDeleteButtons(){
      if(Auth.isAuthenticated){
          return m(Container, {class: "m-1 p-1"}, m(Columns,
              m(Columns.Column,
                  m("button.button.is-warning.is-fullwidth",
                      {onclick(){ m.route.set(`/resource-categories/${state.category_id}/edit`); }},
                      m("span.icon", m("i.fa.fa-edit")),
                      m("span", "Edit")
                  )
              ),
              m(Columns.Column,
                  m("button.button.is-danger.is-fullwidth",
                      {onclick(){ triggerDeleteModal(); }},
                      m("span.icon", m("i.fa.fa-trash")),
                      m("span", "Delete")
                  )
              )
          ));
      } else {
          return null;
      }
  }

  function categoryContent(){
      if(!state.category()){
          return m(".block.is-italic.m-1.pt-3.has-text-centered", "Category Not Found");
      } else {
          return m(Container,
              m(Title, {is: "3", class: "m-1 p-1"}, state.category().resource_category_name),
              isPrivate()? m(
                  "span.tag.is-warning.is-medium.is-italic.ml-2.mt-1.mb-1.has-text-weight-normal", "Private"
              ) : null,
              m(DescriptionCollapse, {
                  descriptionStream: state.category.map(cat => cat.resource_category_description),
                  useMarkdown: true
              }),
              editDeleteButtons(),
              m(Subtitle, {is: "3", class: "m-1 p-1"}, "Resources"),
              m(Box, m(ResourceList, {resourcesStream: state.resources}))
          );
      }
  }

  return {
    oninit: () => {
        performAuthCheck();
        ApplicationAPI.getCategoryByID(state.category_id).then(category => {
            state.category(category);
            return ApplicationAPI.getResourcesByCategoryID(state.category_id).then(resources => {
                state.resources(resources);
            });
        }).catch(error => {
           if(error.code !== 404){
               showErrorModal(error);
           }
        });
    },
    view: () => {
      return m(AppPageContainer, [
          categoryContent()
      ]);
    },
  };
};
