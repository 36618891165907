import m from "mithril";

import {AppPageContainer} from "../components/AppPageContainer";
import {performAuthCheck} from "../Utils";
import Container from "mithril-bulma/components/layout/Container";
import Box from "mithril-bulma/components/Box";
import Title from "mithril-bulma/components/Title";
import {EventsCalendar} from "../components/EventsCalendar";


export const EventsPage = () => {
  // Local state ...
  return {
    oninit: () => {
        performAuthCheck(false);
    },
    view: () => {
      return m(AppPageContainer, [
        m(Container,
            m(Title, {is: "4", class: "m-1 p-1"}, "Events"),
            m(Box,
              m(EventsCalendar)
            )
        )
      ]);
    },
  };
};
