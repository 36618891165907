import m from "mithril";
import Stream from "mithril/stream/stream";
import {split, streamUnmerge} from "../Utils";

export const ListItem = () => {
    return {
        view: (vnode) => {
            let [contentStream, attrs1] = split(vnode.attrs, "contentStream", Stream());
            let [controlsStream, attrs2] = split(attrs1, "controlsStream", Stream());
            let [title, actualAttrs] = split(attrs2, "title", "???");

            if(contentStream()){
                return m(".list-item", {...actualAttrs},
                    m(".list-item-content",
                        m(".list-item-title", title),
                        m(".list-item-description", contentStream())
                    ),
                    controlsStream() ? m(".list-item.controls",
                        controlsStream()
                    ) : null,
                    vnode.children
                );
            } else {
                return null;
            }
        }
    };
};

export const generateMultipleListItems = (contentItemsStream, title) => {
    return streamUnmerge(contentItemsStream).map(stream =>
        m(ListItem, {
            contentStream: stream,
            title: title
        })
    );
};

export const ListBox = () => {
  return {
    view: (vnode) => {
      return m(".list.box", {...vnode.attrs}, vnode.children);
    },
  };
};