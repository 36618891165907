import m from "mithril";
import Stream from "mithril/stream/stream";

import {DescriptionCollapse} from "../components/DescriptionCollapse";
import {ApplicationAPI} from "../services/ApplicationAPI";
import Title from "mithril-bulma/components/Title";
import Box from "mithril-bulma/components/Box";
import Container from "mithril-bulma/components/layout/Container";
import {cleanUrl, performAuthCheck, streamUnmerge} from "../Utils";
import {AppPageContainer} from "../components/AppPageContainer";
import {showDeleteModal, showErrorModal} from "../components/AppModal";
import {ListBox, ListItem, generateMultipleListItems} from "../components/List";
import Columns from "mithril-bulma/components/layout/Columns";
import {Auth} from "../services/Auth";


export const ResourcePage = () => {
  let state = {
      resource: Stream(),
      primary_category: Stream(),
      secondary_categories: Stream(),
      resource_id: m.route.param("key")
  };

  function triggerDeleteModal(){
      showDeleteModal(
          `Delete Resource '${state.resource().resource_name}'?`,
          `Are you sure you want to delete resource '${state.resource().resource_name}'?`,
          function(callback){
              ApplicationAPI.deleteResourceByID(state.resource_id).then(res => {
                  callback();
                  m.route.set("/");
              }).catch(error => {
                  showErrorModal(error);
              });
          }
      );
  }

  function isPrivate(){
      return state.resource()? state.resource().is_private: false;
  }

  function isCategoryPrivate(){
      return state.primary_category()? state.primary_category().is_private: false;
  }


  function editDeleteButtons(){
      if(Auth.isAuthenticated){
          return m(Container, {class: "m-1 p-1"}, m(Columns,
              m(Columns.Column,
                  m("button.button.is-warning.is-fullwidth",
                      {onclick(){ m.route.set(`/resources/${state.resource_id}/edit`); }},
                      m("span.icon", m("i.fa.fa-edit")),
                      m("span", "Edit")
                  )
              ),
              m(Columns.Column,
                  m("button.button.is-danger.is-fullwidth",
                      {onclick(){ triggerDeleteModal(); }},
                      m("span.icon", m("i.fa.fa-trash")),
                      m("span", "Delete")
                  )
              )
          ));
      } else {
          return null;
      }
  }

  function categoryItem(catStream, title){
      return m(ListItem, {
          contentStream: catStream.map(cat => cat.resource_category_name),
          controlsStream: catStream.map(cat =>
            m("button.button.is-right.is-link",
                {onclick(){ m.route.set(`/resource-categories/${cat.resource_category_id}`); }},
                "View"
            )
          ),
          title: title
      });
  }

  function resourceContent(){
      if(!state.resource()){
          return m(".block.is-italic.m-1.pt-3.has-text-centered", "Resource Not Found");
      } else {
          return m(Container, {class: "mt-1"},
              m(Title, {is: "3", class: "m-1 p-1"}, state.resource().resource_name),
              isPrivate()? m(
                  "span.tag.is-warning.is-medium.is-italic.ml-2.mt-1.mb-1.has-text-weight-normal", "Private"
              ) : null,
              isCategoryPrivate()? m(
                  "span.tag.is-warning.is-medium.is-italic.ml-2.mt-1.mb-1.has-text-weight-normal",
                  "Primary Category Private"
              ) : null,
              m(DescriptionCollapse, {
                  descriptionStream: state.resource.map(res => res.resource_description),
                  useMarkdown: true
              }),
              editDeleteButtons(),
              m(ListBox,
                  categoryItem(state.primary_category, "Category"),
                  m(ListItem, {
                      contentStream: state.resource.map(res =>
                          res.resource_location ?
                          m("a", {href: `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(res.resource_location)}`},
                            m("span.icon", m("i.fa.fa-location-dot")),
                            m("span", res.resource_location)
                          ) : null
                      ),
                      title: "Location"
                  }),
                  generateMultipleListItems(
                      state.resource.map(res => res.url_values.map(url =>
                          m("a", {href: cleanUrl(url)}, url)
                      )),
                      "URL"
                  ),
                  generateMultipleListItems(
                      state.resource.map(res => res.contact_info_values),
                      "Contact Info"
                  ),
                  m(ListItem, {
                      contentStream: state.resource.map(res =>
                        res.tags && res.tags.length > 0 ? m(".tags",
                            res.tags.map(tag_name =>
                                m("span.tag.is-info.is-medium", tag_name)
                            )
                        ): null
                      ),
                      title: "Tags"
                  }),
                  streamUnmerge(state.secondary_categories).map(stream =>
                    categoryItem(stream, "Secondary Category")
                  )
              )
          );
      }
  }

  return {
    oninit: () => {
        performAuthCheck();
        ApplicationAPI.getResourceByID(state.resource_id).then(resource => {
           state.resource(resource);
           return ApplicationAPI.getResourceAssociatedCategoryData(
               state.resource_id
           );
        }).then(category_data => {
            state.primary_category(category_data.primary_category);
            state.secondary_categories(category_data.secondary_categories);
        }).catch(error => {
            if(error.code !== 404){
                showErrorModal(error);
            }
        });
    },
    view: () => {
      return m(AppPageContainer, [
          resourceContent()
      ]);
    },
  };
};
