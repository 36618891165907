import m from "mithril";
import Stream from "mithril/stream/stream";

import {performAuthCheck} from "../Utils";
import {AppPageContainer} from "../components/AppPageContainer";
import Container from "mithril-bulma/components/layout/Container";
import Title from "mithril-bulma/components/Title";
import Box from "mithril-bulma/components/Box";
import {Input} from "../components/Input";
import {showSearchTips} from "../components/SearchWidget";
import {SettingsAPI} from "../services/SettingsAPI";


export const HomePage = () => {
  let state = {
    quickSearchValue: Stream(""),
    links: Stream()
  };

  function runSearch(){
      return m.route.set(
        "/search/results",
        {query: state.quickSearchValue()}
      );
  }

  return {
    oninit: () => {
        performAuthCheck();
        SettingsAPI.getAppSettings().then(
            res => state.links(res.links)
        );
    },
    view: () => {
      return m(AppPageContainer, [
          m(Container,
              m(Title, {is: "3", class: "m-1 p-1"}, "Welcome"),
              m(Box,
                  m(Input, {
                      id: "home-page-quick-search-input",
                      inputStream: state.quickSearchValue,
                      label: "Quick Search",
                      oncreate(vnode){
                         vnode.dom.addEventListener(
                            "keydown", e => {
                                if(e.code === "Enter"){
                                    runSearch();
                                }
                             }
                         );
                      }
                  }),
                  m("button.button.is-link.is-fullwidth.mb-2",
                    {
                        id: "home-page-quick-search-submit",
                        onclick(){
                            runSearch();
                        }
                    },
                    m("span.icon", m("i.fa.fa-magnifying-glass")),
                    m("span", "Search")
                ),
                m("a.is-size-6.is-italic",
                    {
                        onclick(){
                            showSearchTips();
                        }
                    },
                    "Search Tips"
                ),
                m("hr"),
                m("button.button.is-primary.is-fullwidth.m-1.p-1",
                    {
                        onclick(){ m.route.set("/browse"); }
                    },
                    m("span.icon", m("i.fa.fa-book")),
                    m("span", "Browse")
                ),
                m("button.button.is-primary.is-fullwidth.m-1.p-1",
                    {
                        onclick(){ m.route.set("/search"); }
                    },
                    m("span.icon", m("i.fa.fa-magnifying-glass")),
                    m("span", "Advanced Search")
                ),
                /*m("button.button.is-primary.is-fullwidth.m-1.p-1",
                    {
                        onclick(){ m.route.set("/events") }
                    },
                    m("span.icon", m("i.fa.fa-calendar")),
                    m("span", "Events")
                ),*/
                state.links() ? state.links().map(l => {
                    return m("a.button.is-info.is-fullwidth.m-1.p-1", {href: l.url},
                        m("span.icon", m("i.fa.fa-link")),
                        m("span", l.name)
                    );
                }) : null
              )
          )
      ]);
    },
  };
};
