import m from "mithril";

import {ApplicationAPI} from "../services/ApplicationAPI";
import {AppPageContainer} from "../components/AppPageContainer";
import {performAuthCheck} from "../Utils";
import Container from "mithril-bulma/components/layout/Container";
import Box from "mithril-bulma/components/Box";
import Title from "mithril-bulma/components/Title";
import Stream from "mithril/stream/stream";
import {NonEmptyInput} from "../components/Input";
import {showErrorModal} from "../components/AppModal";
import {Auth} from "../services/Auth";


export const ResetPasswordPage = (vnode) => {
let state = {
      userID: Stream(m.route.param("key")),
      resetCode: Stream(m.route.param("resetCode")),
      newPassword: Stream(),
      newPasswordAgain: Stream()
  };

  let passwordMinLength = 8;

  function validatePassword(value, label){
      if(value.length < passwordMinLength){
          return `${label} must be at least 8 characters`;
      } else if(state.newPassword() !== state.newPasswordAgain()) {
          return "Password values must match.";
      }
       else {
          return null;
      }
  }

  function canSubmit(){
      return state.userID() && state.resetCode() &&
          state.newPassword() && state.newPassword().length >= passwordMinLength &&
          state.newPasswordAgain() && state.newPasswordAgain() === state.newPassword();
  }

  return {
    oninit: () => {
        performAuthCheck();
    },
    view: () => {
      return m(AppPageContainer, [
          m(Container,
              m(Title, {is: "3", class: "m-1 p-1"}, "Reset Password"),
              m(Box,
                  m(NonEmptyInput, {
                      inputStream: state.newPassword,
                      label: "Password",
                      inputType: "password",
                      inputValidator: validatePassword
                  }),
                  m(NonEmptyInput, {
                      inputStream: state.newPasswordAgain,
                      label: "Password (repeated)",
                      inputType: "password",
                      inputValidator: validatePassword
                  }),
                  m("button.button.is-link.is-fullwidth",
                    {
                        disabled: !canSubmit(),
                        onclick(){
                            Auth.clearAuthData();
                            ApplicationAPI.completePasswordReset(
                                state.userID(),
                                state.resetCode(),
                                state.newPassword()
                            ).then(resp => {
                                m.route.set("/login");
                            }).catch(e => showErrorModal(e));
                        }
                    },
                    m("span.icon", m("i.fa.fa-user-plus")),
                    m("span", "Reset Password")
                )
              )
          )
      ]);
    },
  };
};
