import m from "mithril";
import Stream from "mithril/stream/stream";

import {ApplicationAPI} from "../services/ApplicationAPI";
import Container from "mithril-bulma/components/layout/Container";
import Title from "mithril-bulma/components/Title";
import Box from "mithril-bulma/components/Box";
import {performAuthCheck} from "../Utils";
import {getModal, showErrorModal} from "../components/AppModal";
import {AppPageContainer} from "../components/AppPageContainer";
import {NonEmptyInput} from "../components/Input";
import {MarkdownV2} from "../components/Markdown";
import {PrivateSwitchInput} from "../components/PrivateSwitch";

export const AddCategoryPage = () => {
  let state = {
      category_name: Stream(""),
      category_description: Stream(""),
      is_private: Stream(false)
  };

  function submit_allowed(){
      return state.category_name() !== "";
  }

  return {
    oninit: () => {
        performAuthCheck(true);
    },
    view: () => {
      return m(AppPageContainer, [
          m(Container,
              m(Title, {is: "4", class: "m-1 p-1"}, "Add New Resource Category"),
              m(Box,
                  m(NonEmptyInput, {
                      inputStream: state.category_name,
                      label: "Category Name"
                  }),
                  m(PrivateSwitchInput, {
                      tooltip: "When set to private, this category and all of its resources will be " +
                                "hidden from any user that is not logged in.",
                      isPrivateStream: state.is_private
                  }),
                  m("div.field",
                    m("label.label", "Description"),
                    m(MarkdownV2.MarkdownEditor, {contentStream: state.category_description}),
                    m(".is-italic", "Description rendered as ",
                      m("a", {href: "https://www.markdownguide.org/basic-syntax/"}, "Markdown"))
                  ),
                  m("div.field.is-grouped",
                    m("div.control.is-expanded",
                        m("button.button.is-link.is-fullwidth",
                            {
                                onclick(){
                                    if(submit_allowed()){
                                        ApplicationAPI.addCategory(
                                            state.category_name(),
                                            state.category_description(),
                                            state.is_private()
                                        ).then(result => {
                                            m.route.set(`/resource-categories/${result.resource_category_id}`);
                                        }).catch(err => {
                                            showErrorModal(err);
                                        });
                                    }
                                }
                            },
                            "Submit")
                    ),
                    m("div.control.is-expanded",
                        m("button.button.is-link.is-light.is-fullwidth",
                            {
                                onclick(){
                                    window.history.go(-1);
                                }
                            },
                            "Cancel")
                    )
                  )
              )
          )
      ]);
    },
  };
};
