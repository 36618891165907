import m from "mithril";
import "./App.css";

import {HomePage} from "./pages/HomePage";
import {LoginPage} from "./pages/LoginPage";
import {ForgotPasswordPage} from "./pages/ForgotPasswordPage";
import {BrowsePage} from "./pages/BrowsePage";
import {SearchPage} from "./pages/SearchPage";
import {AboutPage} from "./pages/AboutPage";
import {CategoryPage} from "./pages/CategoryPage";
import {ResourcePage} from "./pages/ResourcePage";
import {EditCategoryPage} from "./pages/EditCategoryPage";
import {AddCategoryPage} from "./pages/AddCategoryPage";
import {AddResourcePage} from "./pages/AddResourcePage";
import {EditResourcePage} from "./pages/EditResourcePage";
import {SearchResultsPage} from "./pages/SearchResultsPage";
import {TagPage} from "./pages/TagPage";
import {UsersPage} from "./pages/UsersPage";
import {NewUserPage} from "./pages/NewUserPage";
import {ResetPasswordPage} from "./pages/ResetPasswordPage";
import {EventsPage} from "./pages/EventsPage";
import {ErrorPage} from "./pages/ErrorPage";
import {ExtNewUserPage} from "./pages/ExtNewUserPage";
import {SettingsPage} from "./pages/misc_admin/SettingsPage";
import {PageNotFoundPage} from "./pages/PageNotFoundPage";

const mountNode = document.querySelector("#app");
m.route(mountNode, "/", {
   "/": HomePage,
   "/login": LoginPage,
   "/forgotPassword": ForgotPasswordPage,
   "/browse": BrowsePage,
   "/search": SearchPage,
   "/search/results": SearchResultsPage,
   "/about": AboutPage,
   "/resource-categories/add": AddCategoryPage,
   "/resource-categories/:key": CategoryPage,
   "/resource-tags/:key": TagPage,
   "/resource-categories/:key/edit": EditCategoryPage,
   "/resources/add": AddResourcePage,
   "/resources/:key": ResourcePage,
   "/resources/:key/edit": EditResourcePage,
   "/events": EventsPage,
   "/error": ErrorPage,
   "/users": UsersPage,
   "/users/:key/resetPassword/:resetCode": ResetPasswordPage,
   "/users/new/ext": ExtNewUserPage,
   "/users/new/:key": NewUserPage,
   "/settings": SettingsPage,
   "/:404...": PageNotFoundPage
});
