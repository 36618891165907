import m from "mithril";
import Stream from "mithril/stream";

import {AppPageContainer} from "../../components/AppPageContainer";
import {censor, performAuthCheck, streamUnmerge, boolAttrsFix} from "../../Utils";
import Container from "mithril-bulma/components/layout/Container";
import Box from "mithril-bulma/components/Box";
import Title from "mithril-bulma/components/Title";
import {NonEmptyInput, URLValidator} from "../../components/Input";
import {showErrorModal} from "../../components/AppModal";
import {Auth} from "../../services/Auth";
import {SettingsAPI} from "../../services/SettingsAPI";

const LinkElement = (initialVnode) => {
  let state = {
      originalLink: null,
      linkStream: initialVnode.attrs.link || Stream({name: "", url: ""})
  };

  return {
     oninit(vnode){
         state.linkStream = vnode.attrs.link;
         state.originalLink = state.linkStream();
     },
     onupdate(vnode){
         state.linkStream = vnode.attrs.link;
     },
     view(vnode) {
        state.linkStream = vnode.attrs.link;
        return state.linkStream() ? m(".edit-link-element",
            {...censor(vnode.attrs, ['link', 'onEdit', 'onUp', 'onDown', 'onDelete'])},
            m(NonEmptyInput, {
                inputStream: state.linkStream.map(l => l? l.name: ""),
                label: "Name",
                isHorizontal: true,
                oninput(e) {
                    vnode.attrs.onEdit(e.target.value, state.linkStream().url);
                },
                name: "name",
                required: true
            }),
            m(NonEmptyInput, {
                inputStream: state.linkStream.map(l => l? l.url: ""),
                label: "URL",
                isHorizontal: true,
                inputValidator: URLValidator,
                inputType: "url",
                oninput(e) {
                    vnode.attrs.onEdit(state.linkStream().name, e.target.value);
                },
                name: "url",
                required: true
            }),
            m(".field.is-horizontal", m(".field-label"),
                m(".field-body", m(".field.is-grouped.pt-1",
                    m(".control.is-expanded",
                        m("button.button.is-fullwidth",
                            {
                                onclick(e){
                                    vnode.attrs.onUp();
                                }
                            },
                            m("span.icon", m("i.fa.fa-chevron-up")), m("span", "Move Up"))
                    ),
                    m(".control.is-expanded",
                        m("button.button.is-fullwidth",
                            {
                                onclick(e){
                                    vnode.attrs.onDown();
                                }
                            },
                            m("span.icon", m("i.fa.fa-chevron-down")), m("span", "Move Down"))
                    ),
                    m(".control.is-expanded",
                        m("button.button.is-danger.is-fullwidth",
                            {
                                onclick(e){
                                    vnode.attrs.onDelete();
                                }
                            },
                            m("span.icon", m("i.fa.fa-trash")), m("span", "Delete")
                        )
                    )
                ))
            )
        ) : null;
     }
  };
};

export const SettingsPage = () => {
  let state = {
      originalSettings: null,
      settings: Stream()
  };

  function refreshSettingsData(){
      return SettingsAPI.getAppSettings(true).then(val => {
          state.settings(val);
          state.originalSettings = val;
      });
  }

  function unchanged(){
      return state.settings() === state.originalSettings;
  }

  function submitAllowed(){
      return state.settings() !== undefined && state.settings().links.every(
          link => link.name && (URLValidator(link.url, "") === null)
      ) && !unchanged();
  }

  function applyAndUpdateLinks(apply){
      if(state.settings()){
          let settings = state.settings();
          state.settings({
              ...settings,
              links: apply(settings.links)
          });
      }
  }

  function createLink(){
      applyAndUpdateLinks(links => {
          links.push({name: "", url: ""});
          return links;
      });
  }

  function editLink(name, url, index){
      applyAndUpdateLinks(links => {
          links[index] = {name, url};
          return links;
      });
  }

  function moveLink(index, direction){
      applyAndUpdateLinks(links => {
          if((direction === 1 && index > 0) || (direction === -1 && index < links.length)){
              let element = links[index];
              links.splice(index, 1);
              links.splice(index - direction, 0, element);
          }
          return links;
      });
  }

  function deleteLink(index) {
      applyAndUpdateLinks(links => {
          if(index < links.length && index >= 0){
              links.splice(index, 1);
          }
          return links;
      });
  }

  return {
    oninit: () => {
        performAuthCheck(true);
        refreshSettingsData();
    },
    view: () => {
      console.log(`Unchanged: ${unchanged()}`);
      return m(AppPageContainer, [
        m(Container,
            m(Title, {is: "4", class: "m-1 p-1"}, "Update Application Settings"),
            m(Box,
                streamUnmerge(state.settings.map(s => s.links)).map((stream, index) =>
                    m(LinkElement, {
                        key: `link-${index}`,
                        link: stream,
                        onEdit: (name, url) => editLink(name, url, index),
                        onUp: () => moveLink(index, 1),
                        onDown: () => moveLink(index, -1),
                        onDelete: () => deleteLink(index)
                    })
                ),
                m("div.field.is-grouped",
                    m("div.control.is-expanded",
                        m("button.button.is-primary.is-fullwidth",
                            {
                                onclick: createLink
                            },
                            m("span.icon", m("i.fa.fa-plus")), m("span", "Add New Link")
                        )
                    ),
                    m("div.control.is-expanded",
                        m("button.button.is-warning.is-fullwidth",
                            {
                                onclick(e){
                                    if(submitAllowed()) {
                                        SettingsAPI.setApplicationSettings(state.settings()).then(res =>
                                            m.route.set("/")
                                        ).catch(error =>
                                            showErrorModal(error)
                                        );
                                    }
                                },
                                ...boolAttrsFix({disabled: !submitAllowed()})
                            },
                            m("span.icon", m("i.fa.fa-floppy-disk")), m("span", "Save Changes")
                        )
                    ),
                    m("div.control.is-expanded",
                        m("button.button.is-link.is-light.is-fullwidth",
                            {
                                onclick: refreshSettingsData,
                                ...boolAttrsFix({disabled: unchanged()})
                            },
                            m("span.icon", m("i.fa.fa-clock-rotate-left")), m("span", "Revert")
                        )
                    )
                )
            )
        )
      ]);
    },
  };
};
