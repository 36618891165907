import m from "mithril";
import Stream from "mithril/stream/stream";

import {ApplicationAPI} from "../services/ApplicationAPI";
import {performAuthCheck, trimIfString} from "../Utils";
import {AppPageContainer} from "../components/AppPageContainer";
import Container from "mithril-bulma/components/layout/Container";
import Title from "mithril-bulma/components/Title";
import {showErrorModal} from "../components/AppModal";
import {newResourceStateStreams, ResourceWriteWidget} from "../components/ResourceWriteWidget";

export const EditResourcePage = () => {
  let state = {
      original_resource: Stream(),
      resource_state_streams: newResourceStateStreams(),
      categories: Stream([]),
      resource_id: m.route.param("key")
  };

  function isPrivate(){
      return state.original_resource()? state.original_resource().is_private: false;
  }

  function isCategoryPrivate(){
      if(state.categories() && state.original_resource()){
          for(let cat of state.categories()){
              if(cat.resource_category_id === state.original_resource().primary_category_id){
                  return cat.is_private;
              }
          }
          return false;
      } else {
          return false;
      }
  }

  function submitAllowed(){
      let resource_name = state.resource_state_streams.resource_name();
      let primary_category_id = state.resource_state_streams.primary_category_id();
      return resource_name && resource_name.length > 0 && primary_category_id !== null;
  }

  function onSubmit(e, stateStreams){
      if(submitAllowed()){
          ApplicationAPI.editResourceByID(
              state.resource_id,
              stateStreams.resource_name(),
              parseInt(stateStreams.primary_category_id()),
              stateStreams.resource_description(),
              trimIfString(stateStreams.resource_location()) || null,
              stateStreams.tags(),
              stateStreams.url_values(),
              stateStreams.contact_info_values(),
              stateStreams.is_private()
          ).then(result => {
             m.route.set(`/resources/${result.resource_id}`);
          }).catch(error => {
              showErrorModal(error);
          });
      }
  }

  return {
    oninit: () => {
        performAuthCheck(true);
        ApplicationAPI.getResourceByID(state.resource_id).then(resource => {
          state.original_resource(resource);
          state.resource_state_streams = newResourceStateStreams(resource);
          return ApplicationAPI.getCategories();
        }).then(categories => { state.categories(categories); }).catch(error => {
          showErrorModal(error);
        });
    },
    view: () => {
        return m(AppPageContainer, [
            state.original_resource() ? m(Container,
                m(Title, {is: "4", class: "m-1 p-1"}, `Edit Resource "${state.original_resource().resource_name}"`),
                isPrivate()? m(
                  "span.tag.is-warning.is-medium.is-italic.ml-2.mt-1.mb-1.has-text-weight-normal", "Private"
                ) : null,
                isCategoryPrivate()? m(
                      "span.tag.is-warning.is-medium.is-italic.ml-2.mt-1.mb-1.has-text-weight-normal",
                      "Primary Category Private"
                ) : null,
                m(ResourceWriteWidget, {
                    resourceStateStreams: state.resource_state_streams,
                    categories: state.categories,
                    onSubmit: onSubmit
                })
            ) : m(".block.is-italic.m-1.pt-3.has-text-centered", "Resource Not Found")
        ]);
    },
  };
};