import m from "mithril";
import Promise from "mithril/promise/promise";
import {CoreServiceConfig} from "./Core";
import {AuthAPI} from "./Auth";
import {almostHomeLinks} from "../Constants";

export const SettingsAPI = {
    _settingsLocalStorageKey: 'ah-application-settings',
    _settingsLocalStorageCacheDuration: 60,
    _settingsSubpath: '/settings.json',
    _fallBackSettings: {
        welcome_text: '',
        links: almostHomeLinks
    },

    get cachedLocalSettings(){
        let api = this;
        let storedSettings = localStorage.getItem(api._settingsLocalStorageKey);
        if(storedSettings !== null) {
            let cached = JSON.parse(storedSettings);
            if((CoreServiceConfig.currentTime() - cached.retrieved) < api._settingsLocalStorageCacheDuration){
                return cached.result;
            } else return null;
        }
        else return null;
    },

    set cachedLocalSettings(value){
        localStorage.setItem(
            this._settingsLocalStorageKey,
            JSON.stringify({
                retrieved: CoreServiceConfig.currentTime(),
                result: value
            })
        );
    },

    get hasCachedLocalAppSettings(){
        return this.cachedLocalSettings !== null;
    },

    get settingsUrl(){
        return CoreServiceConfig.calculateUrl(this._settingsSubpath);
    },

    getAppSettings(forceExpire = false) {
        let api = this;
        let cached = api.cachedLocalSettings;
        if(cached !== null && !forceExpire){
            return Promise.resolve(cached).then(res => {
                m.redraw();
                return res;
            });
        } else {
            return AuthAPI.jwtAuthenticatedRequest({
                url: api.settingsUrl,
                method: 'GET',
                responseType: 'json',
                headers: forceExpire ? {'Cache-Control': 'no-cache'} : undefined,
            }, false).then(res => {
                api.cachedLocalSettings = res;
                return res;
            }).catch(error => {
                console.log(`Unexpected error retrieving settings: ${error}`);
                return api._fallBackSettings;
            });
        }
    },
    setApplicationSettings: function(new_settings){
        return AuthAPI.jwtAuthenticatedRequest({
            url: "/v0/app-settings",
            method: "POST",
            responseType: "json",
            body: new_settings
        }, true).then(
            res => {
                SettingsAPI.cachedLocalSettings = res;
                return res;
            }
        );
    },
};
