import m from "mithril";
import Stream from "mithril/stream/stream";

import {ApplicationAPI} from "../services/ApplicationAPI";
import Title from "mithril-bulma/components/Title";
import Container from "mithril-bulma/components/layout/Container";
import Input from "mithril-bulma/components/input/Input";

import {BulmaTabs} from "../components/BulmaTabs";
import {CategoryList} from "../components/CategoryList";
import {TagList} from "../components/TagList";
import {performAuthCheck} from "../Utils";
import {AppPageContainer} from "../components/AppPageContainer";
import {showErrorModal} from "../components/AppModal";

const TABS = {
    Categories: "Categories",
    Tags: "Tags"
};


export const BrowsePage = () => {
  let state = {
      filter_value: "",
      active_tab: TABS.Categories,
      categories: Stream(),
      tags: Stream()
  };
  function categories(){
    return m(CategoryList, {categoriesStream: state.categories, filterValue: state.filter_value});
  }
  function tags(){
      return m(TagList, {tagsStream: state.tags, filterValue: state.filter_value});
  }

  return {
    oninit: () => {
        performAuthCheck();
        ApplicationAPI.getCategories().then(categories => {
           state.categories(categories);
        }).catch(error => showErrorModal(error));
        ApplicationAPI.getTags().then(tags => {
           state.tags(tags);
        }).catch(error => showErrorModal(error));
    },
    view: () => {
      return m(AppPageContainer, [
          m(Container,
              m(Title, {is: "4", class: "m-1 p-1"}, "Browse"),
              m(BulmaTabs, {class: "is-toggle is-fullwidth"},
                  [
                      m(BulmaTabs.Item,
                          {
                              class: state.active_tab === TABS.Categories ? "is-active" : "",
                              onclick(){ state.active_tab = TABS.Categories; }
                          },
                          TABS.Categories
                      ),
                      m(BulmaTabs.Item,
                          {
                              class: state.active_tab === TABS.Tags ? "is-active" : "",
                              onclick(){ state.active_tab = TABS.Tags; }
                          },
                          TABS.Tags
                      )
                  ]
              ),
              m(Input,
                  {as: "input", type: "text", placeholder: "Filter",
                      oninput() { state.filter_value = this.value; }}, state.filter_value),
              state.active_tab === TABS.Categories ? categories() : null,
              state.active_tab === TABS.Tags ? tags() : null
          )
      ]);
    }
  };
};
