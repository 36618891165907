import m from "mithril";

import {AppPageContainer} from "../components/AppPageContainer";
import {performAuthCheck} from "../Utils";


export const ErrorPage = () => {
  let state = {
      title: m.route.param('title') || '???',
      details: (m.route.param('details') || 'Unknown').replace(/\+/g, ' '),
      raw: m.route.param('raw')
  };
  return {
    oninit: () => {
        performAuthCheck(false);
    },
    view: () => {
      return m(AppPageContainer, [
        m(".error-container", {id: "error-page-info-container"}, m("article.message.is-danger",
            m("div.message-header", m("p", `Error: ${state.title}`)),
            m("div.error-message-body", state.raw ? m("pre", state.details): state.details)
        ))
      ]);
    },
  };
};
