import m from "mithril";
import Stream from "mithril/stream/stream";

import {ApplicationAPI} from "../services/ApplicationAPI";
import {ResourceList} from "../components/ResourceList";

import Title from "mithril-bulma/components/Title";
import Container from "mithril-bulma/components/layout/Container";
import Subtitle from "mithril-bulma/components/Subtitle";
import Box from "mithril-bulma/components/Box";
import {performAuthCheck} from "../Utils";
import Columns from "mithril-bulma/components/layout/Columns";
import {showErrorModal} from "../components/AppModal";
import {AppPageContainer} from "../components/AppPageContainer";

export const TagPage = () => {
  let state = {
      tag_id: parseInt(m.route.param("key")),
      tag: Stream(),
      resources: Stream()
  };

  function categoryContent(){
      if(!state.tag()){
          return m(".block.is-italic.m-1.p-1", "Tag Not Found");
      } else {
          return m(Container,
              m(Title, {is: "3", class: "m-1 p-1"}, "Tag: ", m(".tag.is-info.is-large", state.tag().resource_tag_name)),
              m(Subtitle, {is: "3", class: "m-1 p-1"}, "Resources"),
              m(Box, m(ResourceList, {resourcesStream: state.resources}))
          );
      }
  }

  return {
    oninit: () => {
        performAuthCheck();
        ApplicationAPI.getTagByID(state.tag_id).then(tag => {
            state.tag(tag);
            return ApplicationAPI.getResourcesByTagID(state.tag_id).then(resources => {
                state.resources(resources);
            });
        }).catch(error => {
           if(error.code !== 404){
               showErrorModal(error);
           }
        });
    },
    view: () => {
      return m(AppPageContainer, [
          categoryContent()
      ]);
    },
  };
};
