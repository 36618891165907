import m from "mithril";
import {hideModal, showModal} from "./AppModal";
import QRCode from "qrcode";

export const QRCodeCanvas = (initialVnode) => {
    let id = initialVnode.attrs.id || "qr-code-link-canvas";
    return {
        oncreate(vnode) {
            QRCode.toCanvas(
                vnode.dom, window.location.href, {scale: 6}, (error) => {console.log(error); }
            );
        },
        view(vnode) {
            return m("canvas", {...vnode.attrs, style: "margin: auto", id});
        }
    };
};


export function QRCodeLinkButtonOnClick(e){
    return showModal(
        m(".modal-card",
            m("header.modal-card-head",
                m("p.modal-card-title", "QR Code Link"),
                m("button.delete", {"aria-label": "close", onclick(){ hideModal(); }})
            ),
            m("section.modal-card-body",
                m("div.columns.is-centered", m("div.column.has-text-centered.",
                    m(QRCodeCanvas, {id: "qr-code-modal-canvas"}),
                    m("div.is-italic", "Use this QR code to get a sharable link to the current page."),
                )),
                m("hr"),
                m("button.button.is-fullwidth",
                    {onclick(){ hideModal(); }},
                    "Close"
                )
            )
        )
    );
}


export const QRCodeLinkButton = (initialVnode) => {
    return {
        view(vnode) {
            return m("span.icon", {...vnode.attrs}, m("i.fa.fa-qrcode"));
        }
    };
};