import m from "mithril";

import {AppPageContainer} from "../components/AppPageContainer";
import {performAuthCheck} from "../Utils";
import Container from "mithril-bulma/components/layout/Container";
import Box from "mithril-bulma/components/Box";
import Title from "mithril-bulma/components/Title";
import {CoreServiceConfig} from "../services/Core";


export const AboutPage = () => {
  // Local state ...
  return {
    oninit: () => {
        performAuthCheck(false);
    },
    view: () => {
      return m(AppPageContainer, [
        m(Container,
            m(Title, {is: "4", class: "m-1 p-1"}, "About"),
            m(Box,
                m("p", "Designed by:"),
                m(".image", m("img", {style: "max-width: 600px;", src: `${CoreServiceConfig.calculateUrl('/images/cs-logo.png')}`, alt: "Cope Systems" })),
                m("hr"),
                m(".block", m("a", {href: "https://twitter.com/copesystems"}, m("span.icon", m("i.fa-brands.fa-twitter")), m("span", "@CopeSystems"))),
                m(".block", m("a", {href: "https://github.com/cope-systems"}, m("span.icon", m("i.fa-brands.fa-github")), m("span", "cope-systems"))),
                m("hr"),
                m("a.button.is-link.is-fullwidth", {href: `${CoreServiceConfig.calculateUrl('/v0/ui/')}`}, "API Documentation")
            )
        )
      ]);
    },
  };
};
