import m from "mithril";
import Stream from "mithril/stream/stream";

import {ApplicationAPI} from "../services/ApplicationAPI";
import {performAuthCheck} from "../Utils";
import {AppPageContainer} from "../components/AppPageContainer";
import Container from "mithril-bulma/components/layout/Container";
import Title from "mithril-bulma/components/Title";
import Box from "mithril-bulma/components/Box";
import {NonEmptyInput, BasicEmailValidator} from "../components/Input";
import {Auth, AuthAPI} from "../services/Auth";

export const NewUserPage = () => {
  let state = {
      invitationCode: Stream(m.route.param("key")),
      username: Stream(),
      userDisplayName: Stream(),
      emailAddress: Stream(),
      password: Stream()
  };

  let passwordMinLength = 8;

  function validatePassword(value, label){
      if(value.length < passwordMinLength){
          return `${label} must be at least 8 characters`;
      } else {
          return null;
      }
  }

  function canSubmit(){
      return state.password() &&
          state.password().length >= passwordMinLength &&
          state.emailAddress() &&
          BasicEmailValidator(state.emailAddress(), 'Email Address') == null &&
          state.username() && state.userDisplayName() && state.invitationCode();
  }

  return {
    oninit: () => {
        performAuthCheck();
    },
    view: () => {
      return m(AppPageContainer, [
          m(Container,
              m(Title, {is: "3", class: "m-1 p-1"}, "Create A New User"),
              m(Box,
                  m(NonEmptyInput, {
                     inputStream: state.username,
                     label: "Username"
                  }),
                  m(NonEmptyInput, {
                     inputStream: state.userDisplayName,
                     label: "Real Name"
                  }),
                  m(NonEmptyInput, {
                      inputStream: state.emailAddress,
                      label: "Email Address",
                      inputType: "email",
                      inputValidator: BasicEmailValidator
                  }),
                  m(NonEmptyInput, {
                      inputStream: state.password,
                      label: "Password",
                      inputType: "password",
                      inputValidator: validatePassword
                  }),
                  m("button.button.is-link.is-fullwidth",
                    {
                        disabled: !canSubmit(),
                        onclick(){
                            Auth.clearAuthData();
                            ApplicationAPI.createUser(
                                state.username(),
                                state.userDisplayName(),
                                state.emailAddress(),
                                state.password(),
                                state.invitationCode()
                            ).then(res => {
                                Auth.setUsername(state.username());
                                Auth.setPassword(state.password());
                                return AuthAPI.login();
                            }).then(res => m.route.set("/"));
                        }
                    },
                    m("span.icon", m("i.fa.fa-user-plus")),
                    m("span", "Create User")
                )
              )
          )
      ]);
    },
  };
};
