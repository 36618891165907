import m from "mithril";
import Stream from "mithril/stream/stream";

import {ApplicationAPI} from "../services/ApplicationAPI";
import {performAuthCheck, trimIfString} from "../Utils";
import {AppPageContainer} from "../components/AppPageContainer";
import Container from "mithril-bulma/components/layout/Container";
import Title from "mithril-bulma/components/Title";
import {showErrorModal} from "../components/AppModal";
import {newResourceStateStreams, ResourceWriteWidget} from "../components/ResourceWriteWidget";

export const AddResourcePage = () => {
  let state = {
      resource_state_streams: newResourceStateStreams(),
      categories: Stream([])
  };

  function submitAllowed(){
      let resource_name = state.resource_state_streams.resource_name();
      let primary_category_id = state.resource_state_streams.primary_category_id();
      return resource_name && resource_name.length > 0 && primary_category_id !== null;
  }

  function onSubmit(e, stateStreams){
      if(submitAllowed()){
          ApplicationAPI.addResource(
              stateStreams.resource_name(),
              parseInt(stateStreams.primary_category_id()),
              stateStreams.resource_description(),
              trimIfString(stateStreams.resource_location()) || null,
              stateStreams.tags(),
              stateStreams.url_values(),
              stateStreams.contact_info_values(),
              stateStreams.is_private()
          ).then(result => {
             m.route.set(`/resources/${result.resource_id}`);
          }).catch(error => {
              showErrorModal(error);
          });
      }
  }

  return {
    oninit: () => {
        performAuthCheck(true);
        ApplicationAPI.getCategories().then(result => {
          state.categories(result);
        }).catch(error => {
          showErrorModal(error);
        });
    },
    view: () => {
        return m(AppPageContainer, [
            m(Container,
                m(Title, {is: "4", class: "m-1 p-1"}, "Add New Resource"),
                m(ResourceWriteWidget, {
                    resourceStateStreams: state.resource_state_streams,
                    categories: state.categories,
                    onSubmit: onSubmit
                })
            )
        ]);
    },
  };
};
