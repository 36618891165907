import m from "mithril";
import {censor, get} from "../Utils";
import Stream from "mithril/stream/stream";

export const PrivateSwitchInput = () => {
  return {
    view: (vnode) => {
      let isPrivateSteam = get(vnode.attrs, "isPrivateStream", Stream(false));
      let name = get(vnode.attrs, "name", "is-private-input");
      return m("div.field", m(
          "div.pr-2.has-tooltip-right.has-tooltip-warning.has-tooltip-arrow.has-tooltip-multiline",
          {
              "data-tooltip": vnode.attrs.tooltip,
              style: "display: inline-block; "
          },
          m("input.switch.is-warning",
              {
                  id: name,
                  name,
                  checked: isPrivateSteam()? "checked": "",
                  type: "checkbox",
                  onchange(e) {
                    isPrivateSteam(!isPrivateSteam());
                  },
                  ...censor(vnode.attrs, ["isPrivateStream", "tooltip", "name"])
              }
          ),
          m("label.label", {for: name}, "Is Private")
      ));
    },
  };
};