import m from 'mithril';
import Stream from 'mithril/stream';
import {ApplicationAPI} from "../services/ApplicationAPI";
import Input from "mithril-bulma/components/input/Input";
import Button from "mithril-bulma/components/Button";

import {Captcha} from "../components/Captcha";

import {AuthPageContainer, addNotification} from "../components/AuthPageContainer";
import {Auth} from "../services/Auth";


function FormField(initialVNode){
    return {
        view: function(vnode){
            let {
                label,
                iconClass,
                ...actualAttrs
            } = vnode.attrs;
            return m(".field",
                [
                    m("label",
                        {
                            class: "label",
                            for: vnode.attrs.id
                        },
                        vnode.attrs.label
                    ),
                    m(".control.has-icons-left",
                        [
                            m(Input,
                                {
                                    as: "input",
                                   ...actualAttrs
                                }
                            ),
                            m("span.icon.is-small.is-left",
                                m(`i.fa.${vnode.attrs.iconClass}`)
                            )
                        ]
                    )
                ]
            );
        }
    };
}


export function ForgotPasswordPage(initialVNode){
    let state = {
        resetUsernameOrEmailStream: Stream(),
        userCaptchaTextStream: Stream(),
        captchaTokenStream: Stream()
    };

    return {
        view(vnode){
            return m(AuthPageContainer,
                {
                    title: "Resource App -- Reset Password"
                },
                m(FormField,
                  {
                      label: "Username or Email Address",
                      id: "username_or_email",
                      type: "text",
                      placeholder: "Username or Email Address",
                      required: true,
                      iconClass: "fa-user",
                      oninput: function(e){ state.resetUsernameOrEmailStream(e.target.value); },
                      value: state.resetUsernameOrEmailStream()
                  }
                ),
                m(Captcha,
                  {
                      userCaptchaTextStream: state.userCaptchaTextStream,
                      captchaTokenStream: state.captchaTokenStream
                  }
                ),
                m(".field",
                  m(Button,
                      {
                          is: "success fullwidth",
                          disabled: !state.captchaTokenStream() || !state.userCaptchaTextStream()
                              || !state.resetUsernameOrEmailStream(),
                          onclick: function(e) {
                              // Without the call to "preventDefault",
                              // the entire page will reload and the login will
                              // fail mysteriously. This shows up as "CANCELLED" on the XHR
                              // in Chrome, and shows no XHR in Firefox, and causes the login
                              // chain to fail as the response is null.
                              e.preventDefault();
                              ApplicationAPI.requestPasswordReset(
                                  state.resetUsernameOrEmailStream(),
                                  state.userCaptchaTextStream(),
                                  state.captchaTokenStream()
                              ).then(resp => {
                                  addNotification(
                                      'Success! If the email is valid and in the database, you should see an ' +
                                      'email to reset your password soon', 'success');
                                  state.resetUsernameOrEmailStream = Stream();
                                  state.userCaptchaTextStream = Stream();
                              }).catch(e => {
                                  addNotification(`Error: ${e.response.error || '???'}`);
                              });
                          }
                      },
                      "Submit"
                  )
                ),
                m("a.is-italic",
                  {onclick(){ m.route.set("/login")}},
                  "Login?"
                )
            );
        },
        oninit: function(vnode){
            if(Auth.isAuthenticated){
                m.route.set("/");
            }
        }
    };
}