import m from "mithril";
import {censor, get} from "../Utils";
import Stream from "mithril/stream/stream";
import {NonEmptyInput} from "./Input";
import {showErrorModal} from "./AppModal";
import {AuthAPI} from "../services/Auth";

export const Captcha = (initialVnode) => {
    let state = {
        captchaData: Stream(),
        captchaTokenStream: get(initialVnode.attrs, 'captchaTokenStream', Stream())
    };
    function reloadCaptchaData(){
        AuthAPI.getCaptchaData().then(result => {
            state.captchaData(result);
            state.captchaTokenStream(result.token);
        }).catch(
            error => showErrorModal(error)
        );
    }

    return {
        oninit(vnode) {
            reloadCaptchaData();
        },
        view: (vnode) => {
            let actualAttrs = censor(
                vnode.attrs,
                ["userCaptchaTextStream", "captchaTokenStream"]
            );
            return m(".block",
                {...actualAttrs},
                m(".pb-1.is-size-6.is-italic.is-bold", "Enter the text shown below:"),
                state.captchaData() ? m("img", {
                    src: `data:image/png;base64,${state.captchaData().image}`
                }) : null,
                m(NonEmptyInput, {
                    label: "Captcha",
                    suppressLabel: true,
                    inputStream: get(vnode.attrs, 'userCaptchaTextStream', Stream())
                }),
                m("button.button.is-secondary.is-fullwidth.",
                    {onclick(e){ e.preventDefault(); reloadCaptchaData(); }},
                    m("span.icon.is-small",
                        m("i.fa.fa-refresh")
                    ),
                    m("span", "Reload Captcha")
                )
            );
        },
    };
};