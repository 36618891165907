import m from "mithril";

import {split} from "../Utils";

export const TagList = (initialVNode) => {
    return {
        view: (vnode) => {
            let [tagsStream, someAttrs] = split(vnode.attrs, "tagsStream");
            let [filterValue, attrs] = split(someAttrs, "filterValue");
            if(tagsStream() && tagsStream().length > 0) {
                return m(".list", {...attrs},
                    tagsStream().filter(
                        tag => filterValue === null || tag.resource_tag_name.toLowerCase().includes(filterValue.toLowerCase())
                    ).map(tag => {
                      return m("a.list-item", {
                              onclick(){ m.route.set(`/resource-tags/${tag.resource_tag_id}`);},
                              ...attrs
                          },
                          m(".list-item-title", {...attrs}, m(".tag.is-info.is-medium", tag.resource_tag_name))
                      );
                    })
                );
            } else {
                return m(".block.is-italic.m-1.p-1", "None Yet");
            }
        }
    };
};
