import {CoreServiceConfig} from "./Core";
import m from "mithril";
import Promise from "mithril/promise/promise";
import {get, censor} from "../Utils";

export const Auth = {
    username: "",
    password: "",
    isAuthenticated: false,
    myUserData: null,
    jwtToken: null,
    _localStorageJwtKey: 'ahra-jwt',
    _localStorageUserDataKey: 'ahra-user-data',
    _userDataRefreshMs: 5 * 60 * 1000,

    setUsername: function (value) {
        Auth.username = value;
    },
    setPassword: function (value) {
        Auth.password = value;
    },
    setIsAuthenticated: function (value) {
        Auth.isAuthenticated = value;
    },
    needsAuthCheck: function () {
        return !Auth.isAuthenticated && Auth.jwtToken === null && Auth.hasSavedJWT();
    },
    needsUserDataRefresh: function () {
        let now = new Date().getTime();
        if (Auth.myUserData === null) {
            Auth.setSavedMyUserData();
        }
        return !(Auth.myUserData !== null && (now - Auth.myUserData.lastCheck) < Auth._userDataRefreshMs);
    },
    setSavedJwt: function (value) {
        Auth.jwtToken = value || localStorage.getItem(Auth._localStorageJwtKey);
        if (Auth.jwtToken !== null) {
            localStorage.setItem(Auth._localStorageJwtKey, Auth.jwtToken);
        }
    },
    setSavedMyUserData: function (value) {
        if (value) {
            let newValue = {
                'userData': value,
                'lastCheck': new Date().getTime()
            };
            localStorage.setItem(Auth._localStorageUserDataKey, JSON.stringify(newValue));
            Auth.myUserData = newValue;
        } else {
            let storedUserData = localStorage.getItem(Auth._localStorageUserDataKey);
            Auth.myUserData = storedUserData ? JSON.parse(storedUserData) : null;
        }
    },
    clearPassword: function(){
        Auth.password = "";
    },
    clearAuthData: function () {
        if (Auth.hasSavedJWT()) {
            localStorage.removeItem(this._localStorageJwtKey);
        }
        if (localStorage.getItem(this._localStorageUserDataKey) !== null) {
            localStorage.removeItem(this._localStorageUserDataKey);
        }
        Auth.jwtToken = null;
        Auth.myUserData = null;
        Auth.clearPassword();
    },
    getSavedJwt: function () {
        if (Auth.jwtToken === null) {
            Auth.setSavedJwt();
        }
        return Auth.jwtToken;
    },
    getMyUserData: function () {
        if (Auth.myUserData === null) {
            Auth.setSavedMyUserData();
        }
        return Auth.myUserData;
    },
    getIsAdmin: function () {
        let myUserData = Auth.getMyUserData();
        return myUserData && (myUserData.userData.is_admin || myUserData.userData.inherited_is_admin);
    },
    getCombinedPermissionNames: function () {
        let myUserData = Auth.getMyUserData();
        let permissionNames = new Set();
        if (myUserData && myUserData.userData.user_global_permissions) {
            for (let perm of myUserData.userData.user_global_permissions) {
                permissionNames.add(perm.permission_name);
            }
        }
        if (myUserData && myUserData.userData.inherited_global_permissions) {
            for (let perm of myUserData.userData.inherited_global_permissions) {
                permissionNames.add(perm.permission_name);
            }
        }
        return permissionNames;
    },
    hasSavedJWT: function () {
        return localStorage.getItem(Auth._localStorageJwtKey) !== null;
    },
    canSubmit: function () {
        return Auth.username !== "" && Auth.password !== "";
    }
};


export const AuthAPI = {
    get jwtLoginURL(){  return CoreServiceConfig.calculateUrl('/auth/jwt-login');  },
    get authConfigURL(){ return CoreServiceConfig.calculateUrl('/auth/config.json'); },
    get captchaURL(){ return CoreServiceConfig.calculateUrl('/api/captcha'); },
    get jwtRefreshURL(){ return CoreServiceConfig.calculateUrl('/auth/jwt-login/refresh'); },
    get myUserDataURL(){ return CoreServiceConfig.calculateUrl('/v0/users/me'); },

    jwtAuthenticatedRequest(args, jwtRequired){
        let savedJWT = Auth.getSavedJwt();
        if(jwtRequired === undefined){
             jwtRequired = get(args, 'jwtRequired', true);
        }
        if(!savedJWT && jwtRequired) throw new Error('Not authenticated!');
        return m.request({
            config: xhr => {
                if(savedJWT) xhr.setRequestHeader('Authorization', `Bearer ${Auth.getSavedJwt()}`);
                xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
                get(args, "config", xhr => {})(xhr);
            },
            timeout: CoreServiceConfig.timeout,
            ...censor(args, ['config', 'jwtRequired'])
        });
    },

    login(){
        let api = this;
        return m.request({
            url: api.jwtLoginURL,
            method: "POST",
            responseType: "json",
            body: {username: Auth.username, password: Auth.password},
            timeout: CoreServiceConfig.timeout
        }).then(function(resp){
            Auth.clearPassword();
            if(resp === null){
                console.log("Login cancelled...");
                throw new Error("Null response for login...");
            }
            Auth.setSavedJwt(resp.token);
            Auth.setIsAuthenticated(true);
            return resp;
        }).then(function (resp){
            return api.refreshUserData();
        }).catch(function(error){
            Auth.clearPassword();
            api.logout();
            throw error;
        });
    },
    checkLogin(){
        let api = this;
        let savedJWT = Auth.getSavedJwt();
        if(savedJWT) {
            return api.jwtAuthenticatedRequest({
                url: api.jwtLoginURL,
                method: "GET",
                responseType: "json"
            }).then(function (resp) {
                Auth.setIsAuthenticated(true);
                return true;
            }).catch(function (error) {
                api.logout();
                throw error;
            });
        } else {
            return Promise.resolve(false);
        }
    },
    refreshLogin: function(){
        let api = this;
        let savedJWT = Auth.getSavedJwt();
        if(savedJWT) {
            return api.jwtAuthenticatedRequest({
                url: api.jwtRefreshURL,
                method: "POST",
                responseType: "json",
            }).then(function (resp) {
                Auth.setSavedJwt(resp.token);
                Auth.setIsAuthenticated(true);
                return true;
            }).catch(function (error) {
                api.logout();
                throw error;
            });
        } else {
            return Promise.resolve(false);
        }
    },
    logout: function(){
        Auth.clearAuthData();
        Auth.setIsAuthenticated(false);
    },
    getMyUserData(){
        let api = this;
        return api.jwtAuthenticatedRequest({
            url: api.myUserDataURL,
            method: "GET",
            responseType: "json"
        });
    },
    refreshUserData: function(){
        return this.getMyUserData().then(
            res => Auth.setSavedMyUserData(res)
        );
    },
    getAuthConfig: function(){
        let api = this;
        return m.request({
            url: api.authConfigURL,
            method: "GET",
            responseType: "json",
            timeout: CoreServiceConfig.timeout
        });
    },
    getCaptchaData(){
        return m.request({
            url: this.captchaURL,
            method: "GET",
            responseType: "json",
            timeout: CoreServiceConfig.timeout
        });
    }
};