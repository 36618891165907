import m from "mithril";

let state = {
    shown: false,
    content: null
};

export function showModal(content){
    state.content = content;
    state.shown = true;
}

export function setContent(content){
    state.content = content;
}


export function showInfoModal(title, message, on_close=null){
    on_close = on_close || function(callback){ callback(); };
    showModal(
        m(".modal-card",
            m("header.modal-card-head",
                m("p.modal-card-title", title),
                m("button.delete", {"aria-label": "close", onclick(){ hideModal(); }})
            ),
            m("section.modal-card-body",
                message,
                m("hr"),
                m("button.button.is-fullwidth",
                    {onclick(){ on_close(hideModal); }},
                    "Close"
                )
            )
        )
    );
}

export function showErrorModal(error){
    let errorMessage, statusCode = error.hasOwnProperty('code')? error.code : -1;
    if(error.hasOwnProperty('response') && error.response.hasOwnProperty('error')){
        errorMessage = error.response.error;
    }
    else {
        errorMessage = "Something went wrong!";
    }
    console.log(`Caught error: ${error}`);
    showModal(
        m(".modal-card",
            m("header.modal-card-head",
                m("p.modal-card-title", `Error`),
                m("button.delete", {"aria-label": "close", onclick(){ hideModal(); }})
            ),
            m("section.modal-card-body",
                m("p", errorMessage),
                m("hr"),
                m("p.is-size-7.is-italic", `Status Code: ${statusCode}`)
            )
        )
    );
}

export function showDeleteModal(title, content, on_yes=null, on_cancel=null, verb="Delete"){
    on_yes = on_yes || function(callback){ callback(); };
    on_cancel = on_cancel || function(callback){ callback(); };

    showModal(
        m(".modal-card",
            m("header.modal-card-head",
                m("p.modal-card-title", title),
                m("button.delete", {"aria-label": "close", onclick(){ on_cancel(hideModal); }})
            ),
            m("section.modal-card-body",
                m("p", content),
            ),
            m("footer.modal-card-foot", m("div.columns", {style: "width: 100%;"}, //TODO: Fixme
                m("div.column", m("button.button.is-danger.is-fullwidth",
                    {onclick(){ on_yes(hideModal); }},
                    `Confirm ${verb}`
                )),
                m("div.column", m("button.button.is-fullwidth",
                    {onclick(){ on_cancel(hideModal); }},
                    "Cancel"
                ))
            ))
        )
    );
}

export function hideModal(){
    state.shown = false;
}

export function getModal(){
    if(state.shown){
        return m("div.modal" + (state.shown ? ".is-active" : ""),
            m("div.modal-background"),
            (typeof state.content) === 'function'? state.content() : state.content,
            m("button.modal-close.is-large",
                {"aria-label": "close", onclick(){ hideModal(); }},
                "Close")
        );
    } else {
        return null;
    }
}