import m from "mithril";
import Stream from "mithril/stream/stream";

import {ApplicationAPI} from "../services/ApplicationAPI";
import Container from "mithril-bulma/components/layout/Container";
import Title from "mithril-bulma/components/Title";
import Box from "mithril-bulma/components/Box";
import {performAuthCheck} from "../Utils";
import {hideModal, showModal} from "../components/AppModal";
import {AppPageContainer} from "../components/AppPageContainer";
import {MultiInput, BasicEmailValidator} from "../components/Input";
import Columns from "mithril-bulma/components/layout/Columns";


export const UsersPage = () => {
  let state = {
      users: Stream(),
      inviteEmailAddresses: Stream([])
  };

  function reloadUsers(){
      return ApplicationAPI.getUsers().then(users => state.users(users));
  }

  function usersList(){
      if(state.users() && state.users().length > 0){
          return m(".list",
              state.users().map(user => {
                  return m(".list-item",
                      m(".list-item-content",
                          m(".list-item-title", user.user_display_name || user.username),
                          m(".list-item-description", `@${user.username}`)
                      )
                  )
              })
          )
      }
      else {
          return m(".block.is-italic.m-1.p-1", "No users loaded yet...");
      }
  }

  function buttons(){
      return m(Container, {class: "m-1 p-1"}, m(Columns,
          m(Columns.Column,
              m("button.button.is-primary.is-fullwidth",
                  {onclick(){ showInviteUsersModal(); }},
                  m("span.icon", m("i.fa.fa-envelope")),
                  m("span", "Invite A New User")
              )
          ),
          m(Columns.Column,
              m("button.button.is-info.is-fullwidth",
                  {onclick(){ reloadUsers(); }},
                  m("span.icon", m("i.fa.fa-arrows-rotate")),
                  m("span", "Refresh")
              )
          )
      ));
  }

  function showInviteUsersModal(){
      function modalContent(){
          return m(".modal-card",
              m("header.modal-card-head",
                  m("p.modal-card-title", "Invite Users"),
                  m("button.delete", {"aria-label": "close", onclick(){ hideModal(); }})
              ),
              m("section.modal-card-body",
                  m(MultiInput, {
                      inputsStream: state.inviteEmailAddresses,
                      label: "Invite Email Address",
                      labelPlural: "Invite Email Addresses",
                      inputType: "email",
                      inputValidator: BasicEmailValidator
                  })
              ),
              m("footer.modal-card-foot", m(Columns, {style: "width: 100%;"}, //TODO: Fixme
                  m(Columns.Column, m("button.button.is-primary.is-fullwidth",
                      {onclick(){
                          ApplicationAPI.createUserInvitation(state.inviteEmailAddresses()).then(
                              resp => {
                                  // hideModal();
                                  state.inviteEmailAddresses([]);
                                  showInviteResponseModal(resp.invitation_code, resp.invitation_expiry_dt);
                              }
                          );
                      }},
                      "Generate Invite"
                  )),
                  m(Columns.Column, m("button.button.is-fullwidth",
                      {onclick(){
                          hideModal();
                      }},
                      "Cancel"
                  ))
              ))
          );
      }
      return showModal(modalContent);
  }

  function showInviteResponseModal(invitationCode, invitationExpiry){
      return showModal(
          m(".modal-card",
              m("header.modal-card-head",
                  m("p.modal-card-title", "User Invitation Code"),
                  m("button.delete", {"aria-label": "close", onclick(){ hideModal(); }})
              ),
              m("section.modal-card-body",
                  m("p", "Invite Users with the following link:"),
                  m(Box, m(m.route.Link, {href: `/users/new/${invitationCode}`}, `${window.location.origin}/?#!/users/new/${invitationCode}`)),
                  m("p", `This invite expires at: ${invitationExpiry}`)
              ),
              m("footer.modal-card-foot", m(Columns, {style: "width: 100%;"}, //TODO: Fixme
                  m(Columns.Column, m("button.button.is-fullwidth",
                      {onclick(){
                          hideModal();
                      }},
                      "Close"
                  ))
              ))
          )
      );
  }

  return {
    oninit: () => {
        performAuthCheck(true);
        reloadUsers();
    },
    view: () => {
      return m(AppPageContainer, [
          m(Container,
              m(Title, {is: "4", class: "m-1 p-1"}, "Users"),
              m(Box,
                  usersList(),
                  m("hr"),
                  buttons()
              )
          )
      ]);
    },
  };
};
