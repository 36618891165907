import m from "mithril";

import {AppPageContainer} from "../components/AppPageContainer";
import {performAuthCheck} from "../Utils";


export const PageNotFoundPage = () => {
  return {
    oninit: () => {
        performAuthCheck(false);
    },
    view: () => {
      return m(AppPageContainer, [
        m(".error-container", {id: "error-page-info-container"}, m("article.message",
            m("div.message-header", m("p", `Page Not Found: ${m.route.get()}`)),
            m("div.error-message-body",
                m("p", "We couldn't find the page you are looking for."),
                m(".columns.mt-4",
                  m(".column", m("button.button.is-link.is-fullwidth", {onclick(){ history.back(); }}, "Navigate Back")),
                  m(".column", m("a.button.is-link.is-fullwidth[href=/]", {oncreate: m.route.link}, "Go To Landing Page"))
                )
            )
        ))
      ]);
    },
  };
};
