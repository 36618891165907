import m from "mithril";

import {appVersion} from "../Version";
import {isDemo} from "../Constants";


export const AppFooter = () => {
  // Local state ...
  return {
    view: () => {
      return m("footer.footer", [
          m("div.content.has-text-centered",
              isDemo? m("p.has-text-danger.is-size-2", "DEMO INSTANCE"): null,
              m("p", m("a", {href:"https://www.almosthomeonline.org/"}, "Almost Home Inc."), ` Resources App -- Version ${appVersion}`)
          )
      ]);
    },
  };
};
