import m from "mithril";
import Stream from "mithril/stream";
import Content from "mithril-bulma/components/Content";
import {
    fromUnixTime, compareAsc, startOfDay, endOfDay,
    getUnixTime, startOfMonth, endOfMonth, subMonths, addMonths
} from "date-fns/esm";
import {utcToZonedTime, zonedTimeToUtc} from "date-fns-tz/esm";
import {ApplicationAPI} from "../services/ApplicationAPI";
import {showErrorModal, showModal, hideModal} from "./AppModal";
import DOMPurify from "dompurify";
import ECCalendar from '@event-calendar/core';
import ECList from '@event-calendar/list';
import ECDayGrid from '@event-calendar/day-grid';
import '@event-calendar/core/index.css';
import Columns from "mithril-bulma/components/layout/Columns";
import {ListBox, ListItem} from "./List";
import {MarkdownV2} from "./Markdown";
import Box from "mithril-bulma/components/Box";
import {Auth} from "../services/Auth";

export const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function calcEventStartDateLocal(event){
    return utcToZonedTime(fromUnixTime(event.event_start_dt), localTimezone);
}

function calcEventEndDateLocal(event){
    return utcToZonedTime(fromUnixTime(event.event_end_dt), localTimezone);
}

function formatDate(date){
    return new Intl.DateTimeFormat(
        'en-US',
        {dateStyle: 'full', timeStyle: 'long', timeZone: localTimezone}
    ).format(date);
}

const CoreCalendar = (initialVnode) => {
    let state = {
        ecCal: null,
        etMap: {}
    };

    function lazyLoadEventTypes(events){
        let needsReload = events.some(e =>
            !state.etMap.hasOwnProperty(e.associated_event_type_id)
        );
        if(needsReload){
            return ApplicationAPI.getEventTypes().then(eventTypes => {
                for(let et of eventTypes){
                    state.etMap[et.event_type_id] = et;
                }
                return events;
            });
        } else {
            return events;
        }

    }

    function generateTags(apiEvent){
        let tagsStr = "";
        if(apiEvent.is_private){
            tagsStr += '<span class="tag is-warning is-small is-italic mr-1 has-text-weight-normal">Private</span>'
        }
        if(state.etMap.hasOwnProperty(apiEvent.associated_event_type_id)){
            let eventTypeName = state.etMap[apiEvent.associated_event_type_id].event_type_name;
            tagsStr += `<span class="tag is-info is-small mr-1 has-text-weight-normal">${DOMPurify.sanitize(eventTypeName)}</span>`
        }
        return tagsStr;
    }

    function transformEvent(apiEvent){
        return {
            id: apiEvent.event_id,
            allDay: apiEvent.is_full_day_event,
            start: calcEventStartDateLocal(apiEvent),
            end: calcEventEndDateLocal(apiEvent),
            title: apiEvent.event_title,
            editable: Auth.isAuthenticated,
            backgroundColor: "#a4cc3c",
            extendedProps: apiEvent
        }
    }

    function fetchEvents(fetchInfo, successCallback, failureCallback){
        let startUTCEpoch = getUnixTime(zonedTimeToUtc(fetchInfo.start, localTimezone));
        let endUTCEpoch = getUnixTime(zonedTimeToUtc(fetchInfo.end, localTimezone));
        return ApplicationAPI.getEvents(
            startUTCEpoch, endUTCEpoch
        ).then(lazyLoadEventTypes).then(
            events => Array.from(events, transformEvent)
        ).catch(error => {
            showErrorModal(error)
        });
    }

    function showEventModal(info){
        let apiEvent = info.event.extendedProps;

        showModal(
            m(".modal-card",
                m("header.modal-card-head",
                    m("p.modal-card-title.is-flex.flex-wrap", {style: "max-width: 95%;"}, info.event.title),
                    m("button.delete", {"aria-label": "close", onclick(){ hideModal() }})
                ),
                m("section.modal-card-body",
                    apiEvent.is_private ? m(
                          "span.tag.is-warning.is-medium.is-italic.ml-2.mt-1.mb-1.has-text-weight-normal", "Private"
                    ) : null,
                    state.etMap.hasOwnProperty(apiEvent.associated_event_type_id) ? m(
                        "span.tag.is-info.is-medium.ml-2.mt-1.mb-1.has-text-weight-normal",
                        state.etMap[apiEvent.associated_event_type_id].event_type_name
                    ): null,
                    apiEvent.is_full_day_event ? m(
                        "span.tag.is-info.is-medium.ml-2.mt-1.mb-1.has-text-weight-normal",
                        "Full Day Event"
                    ): null,
                    apiEvent.event_description.trim() ?
                        m(Box, m(MarkdownV2.MarkdownViewer, {contentStream: Stream(apiEvent.event_description)})) :
                    null,
                    m(ListBox,
                        m(ListItem, {
                            title: 'Start',
                            contentStream: Stream(formatDate(info.event.start))
                        }),
                        m(ListItem, {
                            title: 'End',
                            contentStream: Stream(formatDate(info.event.end))
                        }),
                        apiEvent.event_organizer ? m(ListItem, {

                        }) : null,
                        apiEvent.event_url ? m(ListItem, {

                        }) : null,
                        apiEvent.event_location ? m(ListItem, {

                        }) : null,
                    )
                ),
                Auth.isAuthenticated ? m("footer.modal-card-foot",  m(Columns, {style: "width: 100%;"}, //TODO: Fixme
                    m(Columns.Column, m("button.button.is-warning.is-fullwidth",
                        {onclick(){ m.route.set(`/events/${apiEvent.event_id}/edit`); }},
                        m("span.icon", m("i.fa.fa-edit")),
                        m("span", "Edit")
                    )),
                    m(Columns.Column, m("button.button.is-danger.is-fullwidth",
                        {onclick(){ triggerDeleteModal(); }},
                        m("span.icon", m("i.fa.fa-trash")),
                        m("span", "Delete")
                    ))
                )) : null
            )
        );
        m.redraw();
    }

    return {
        oncreate(vnode){
            state.ecCal = new ECCalendar({
                target: vnode.dom,
                props: {
                    plugins: [ECList, ECDayGrid],
                    options: {
                        headerToolbar: {
                            start: 'prev dayGridMonth,listWeek',
                            center: 'title',
                            end: 'today next'
                        },
                        view: 'listWeek',
                        eventSources: [
                            {events: fetchEvents}
                        ],
                        eventClick: showEventModal,
                        buttonText: {
                            today: 'Today',
                            dayGridMonth: 'Month',
                            listWeek: 'List'
                        },
                        theme(baseTheme){

                            return baseTheme;
                        }
                    }
                }
            });
        },
        onupdate(vnode){
            /*if(state.ecCal !== null){
                state.ecCal.refetchEvents();
            }*/
        },
        view(vnode){
            return m("div.core-calendar")
        }
    }
}

export const EventsCalendar = (initialVnode) => {
  let state = {
      eventTypeID: initialVnode.attrs.eventTypeID || null
  };

  return {
    view(vnode){
      return m("div.calendar",
          m(CoreCalendar)
      );
    },
  };
};
