import m from "mithril";
import Stream from "mithril/stream/stream";
import Navbar from "mithril-bulma/components/Navbar";
import {ApplicationAPI} from "../services/ApplicationAPI";
import {almostHomeLinks} from "../Constants";
import {isDemo} from "../Constants";
import {Auth} from "../services/Auth";
import {CoreServiceConfig} from "../services/Core";
import {SettingsAPI} from "../services/SettingsAPI";
import {QRCodeLinkButton, QRCodeLinkButtonOnClick} from "./QRCodeLink";

const NavbarInternalLinkItem = {
    view: function(vnode) {
        return m(m.route.Link, {
            ...vnode.attrs,
            selector: `a.navbar-item.${vnode.attrs.selector || ''}`
        }, vnode.children);
    }
};

export const AppNavbar = () => {
  // Local state ...
  let navbar_state = {
    active: false,
    ah_content_dropdown_active: false,
    authoring_dropdown_active: false,
    admin_dropdown_active: false,
    links: Stream()
  };

  function is_active(){
    if(navbar_state.active) {
      return "is-active";
    } else {
      return "";
    }
  }

  function generate_auth_button(){
      if(Auth.isAuthenticated){
          return m("a.button.is-light", {
              id: "navbar-logout-button",
              onclick: () => {
                  Auth.clearAuthData();
                  Auth.setIsAuthenticated(false);
                  return m.route.set("/");
              }
          }, "Log Out");
      } else {
          return m("a.button.is-primary", {
              id: "navbar-login-button",
              onclick: () => m.route.set("/login")
          }, "Staff Login");
      }
  }

  function generate_authenticated_options(){
      if(Auth.isAuthenticated){
          return [
              m("hr.navbar-divider"),
              m(Navbar.Item,
                  {class: navbar_state.authoring_dropdown_active? "is-active has-dropdown" : "has-dropdown"},
                  m(Navbar.Link, {onclick(){
                      navbar_state.authoring_dropdown_active = !navbar_state.authoring_dropdown_active;
                      navbar_state.ah_content_dropdown_active = false;
                      navbar_state.admin_dropdown_active = false;
                  }}, "Authoring"),
                  navbar_state.authoring_dropdown_active? m(Navbar.Dropdown,
                      m(NavbarInternalLinkItem, {href: "/resources/add"}, "Add Resources"),
                      m(NavbarInternalLinkItem, {href: "/resource-categories/add"}, "Add Categories"),
                  ) : null
              ),
              m(Navbar.Item,
                  {class: navbar_state.admin_dropdown_active? "is-active has-dropdown": "has-dropdown"},
                  m(Navbar.Link, {onclick(){
                      navbar_state.admin_dropdown_active = !navbar_state.admin_dropdown_active;
                      navbar_state.ah_content_dropdown_active = false;
                      navbar_state.authoring_dropdown_active = false;
                  }}, "Admin"),
                  navbar_state.admin_dropdown_active? m(Navbar.Dropdown,
                      m(NavbarInternalLinkItem, {href: "/settings"}, "Settings"),
                      m(NavbarInternalLinkItem, {href: "/users"}, "Users")
                  ) : null
              )
          ];
      } else {
          return [];
      }
  }



  return {
    oninit(){
        SettingsAPI.getAppSettings().then(result => navbar_state.links(result.links));
    },
    view: () => {
      return m(Navbar, {is: "white", id: "app-navbar"}, [
          m(Navbar.Brand,
              m(Navbar.Item, {class: "brand-text", onclick(){ m.route.set("/"); } },
                  m("img",
                      {
                          style: "object-fit: contain; padding-right: 0.5rem;",
                          src: `${CoreServiceConfig.calculateUrl('/images/ah-logo.png')}`, alt: "Almost Home Inc.",
                          id: 'navbar-logo'
                      }
                  ),
                  "Resource App"
              ),
              m("a.navbar-burger",
                  {
                    role: "button", "aria-label": "menu", "aria-expanded": "false", "data-target": "ah-navbar-menu",
                    onclick: () => { navbar_state.active = !navbar_state.active; }, id: "ah-navbar-menu-burger"
                  },
                  m("span", {"aria-hidden": "true"}),
                  m("span", {"aria-hidden": "true"}),
                  m("span", {"aria-hidden": "true"})
              )
          ),
          m(Navbar.Menu, {id: "ah-navbar-menu", class: is_active()},
              m(Navbar.Start, [
                m(NavbarInternalLinkItem, {href: "/search", id: "navbar-search"}, "Search"),
                m(NavbarInternalLinkItem, {href: "/browse", id: "navbar-browse"}, "Browse"),
                //m(NavbarInternalLinkItem, {href: "/events", id: "navbar-events"}, "Events"),
                m(Navbar.Item,
                  {
                      class: navbar_state.ah_content_dropdown_active? "is-active has-dropdown" : "has-dropdown",
                      id: "navbar-ah-links"
                  },
                  m(Navbar.Link, {onclick(){
                      navbar_state.ah_content_dropdown_active = !navbar_state.ah_content_dropdown_active;
                      navbar_state.authoring_dropdown_active = false;
                      navbar_state.admin_dropdown_active = false;
                  }}, "Almost Home"),
                  navbar_state.ah_content_dropdown_active? m(Navbar.Dropdown,
                      m(Navbar.Item, {href: "https://www.almosthomeonline.org/"}, "Home"),
                      navbar_state.links() ? navbar_state.links().map(l => {
                          return m(Navbar.Item, {href: l.url}, l.name);
                      }) : null
                  ) : null
                ),
                ...generate_authenticated_options(),
                m(NavbarInternalLinkItem, {href: "/about"}, "About")
              ]),
              m(Navbar.End,
                  isDemo? m(Navbar.Item, m(".has-text-danger", "DEMO INSTANCE")): null,
                  m(Navbar.Item, {onclick: QRCodeLinkButtonOnClick},
                      m(QRCodeLinkButton)
                  ),
                  m(Navbar.Item, m(".buttons",
                    generate_auth_button()
                    )
                  )
              )
          ),

      ]);
    },
  };
};
