import m from 'mithril';
import Input from "mithril-bulma/components/input/Input";
import Button from "mithril-bulma/components/Button";

import {AuthPageContainer, addNotification} from "../components/AuthPageContainer";
import {Auth, AuthAPI} from "../services/Auth";


function FormField(initialVNode){
    return {
        view: function(vnode){
            let {
                label,
                iconClass,
                ...actualAttrs
            } = vnode.attrs;
            return m(".field",
                [
                    m("label",
                        {
                            class: "label",
                            for: vnode.attrs.id
                        },
                        vnode.attrs.label
                    ),
                    m(".control.has-icons-left",
                        [
                            m(Input,
                                {
                                    as: "input",
                                   ...actualAttrs
                                }
                            ),
                            m("span.icon.is-small.is-left",
                                m(`i.fa.${vnode.attrs.iconClass}`)
                            )
                        ]
                    )
                ]
            );
        }
    };
}


export function LoginPage(initialVNode){
    let state = {
        ms_auth_enabled: false
    }

    return {
        view(vnode){
            return m(AuthPageContainer,
                m(FormField,
                  {
                      label: "Username",
                      id: "username",
                      type: "text",
                      placeholder: "Username",
                      required: true,
                      iconClass: "fa-user",
                      oninput: function(e){ Auth.setUsername(e.target.value); },
                      value: Auth.username
                  }
                ),
                m(FormField,
                  {
                      label: "Password",
                      id: "password",
                      placeholder: "******",
                      type:"password",
                      required: true,
                      iconClass: "fa-lock",
                      oninput: function(e){ Auth.setPassword(e.target.value); },
                      value: Auth.password
                  }
                ),
                m(".field",
                  m(Button,
                      {
                          id: "login-submit-button",
                          is: "success fullwidth",
                          disabled: !Auth.canSubmit(),
                          onclick: function(e) {
                              // Without the call to "preventDefault",
                              // the entire page will reload and the login will
                              // fail mysteriously. This shows up as "CANCELLED" on the XHR
                              // in Chrome, and shows no XHR in Firefox, and causes the login
                              // chain to fail as the response is null.
                              e.preventDefault();
                              AuthAPI.login().then(function(result){
                                  m.route.set("/");
                              }).catch(function(e){
                                  addNotification(`Authentication failed! ${e.error || ''}`);
                              });
                          }
                      },
                      "Login"
                  )
                ),
                m("a.is-italic",
                  {
                      id: "forgot-password-anchor",
                      onclick(){ m.route.set("/forgotPassword"); }
                  },
                  "Forgot Password?"
                ),
                m("hr"),
                state.ms_auth_enabled ? m("a.button.is-link.is-fullwidth",
                    {
                        id: "sign-in-with-microsoft",
                        href: "/auth/initiate-ms-login"
                    },
                    m("span.icon.is-small.is-left",
                        m("i.fa-brands.fa-microsoft")
                    ),
                    m("span", "Sign in with Microsoft")
                ) : null
            );
        },
        oninit: function(vnode){
            AuthAPI.getAuthConfig().then(res => {
                state.ms_auth_enabled = res.ms_identity_enabled;
            });
            if(Auth.isAuthenticated){
                m.route.set("/");
            }
        }
    };
}